import axios from 'axios';

import { API } from '../../constants';
export default class ProductsServices {
  static async getPlans(sourceCancel) {
    try {
      return await axios.get(`${API.BASE_URL}/plans`, {
        cancelToken: sourceCancel.token,
      });
    } catch (error) {
      if (axios.isCancel(error)) {
        console.warn(
          `Requisição cancelada${error?.message ? `: ${error?.message}` : '.'}`
        );
      } else {
        console.warn('Erro na consulta dos planos: ', error);
      }
    }
  }
}
