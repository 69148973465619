import axios from 'axios';

import { API } from '../../constants';
export default class AuthServices {
  static async getToken(login) {
    try {
      return await axios.post(`${API.BASE_URL}/login`, login);
    } catch (error) {
      console.warn('Erro no login: ', error);
      throw error;
    }
  }
  static async getUser(token) {
    try {
      return await axios.get(`${API.BASE_URL}/token/${token}`);
    } catch (error) {
      console.warn('Erro ao buscar dados do usuário: ', error);
      throw error;
    }
  }
}
