import { title } from "assets/jss/material-kit-react.js";

const productStyle = {
  section: {
    padding: "70px 0",
    textAlign: "center",
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
  },
  description: {
    color: "#999",
  },
  gridItem: {
    borderRadius: "10px",
    paddingBottom: "30px",
  },
  gridItemContrast: {
    paddingBottom: "30px",
    border: "1px solid",
    // borderColor: "#3c4858",
    borderColor: "#f4f4f4",
    margin: "-6px 0px 0px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    borderRadius: "6px",
  },
};

export default productStyle;
