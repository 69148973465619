import axios from 'axios';

import { API } from '../../constants';

export default class ReserveServices {
  static async getReserve({ token, reserveId }) {
    try {
      return await axios.get(`${API.BASE_URL}/reserve/${token}/${reserveId}`);
    } catch (error) {
      console.warn('Erro na consulta da reserva: ', error);
      // throw error;
    }
  }

  static async getSchedule({ token }) {
    try {
      return await axios.get(`${API.BASE_URL}/schedule/${token}`);
    } catch (error) {
      console.warn('Erro na consulta do agendamento: ', error);
      // throw error;
    }
  }

  static async delReserve({ token, reserveId }) {
    try {
      return await axios.post(
        `${API.BASE_URL}/reserve/cancel/${token}/${reserveId}`,
        {}
      );
    } catch (error) {
      console.warn('Erro no cancelamento da reserva: ', error);
      // throw error;
    }
  }

  static async getScheduleMonths(sourceCancel) {
    try {
      return await axios.get(`${API.BASE_URL}/schedule/available/months`, {
        cancelToken: sourceCancel.token,
      });
    } catch (error) {
      if (axios.isCancel(error)) {
        console.warn(
          `Requisição cancelada${error?.message ? `: ${error?.message}` : '.'}`
        );
      } else {
        console.warn('Erro na consulta dos meses disponíveis: ', error);
      }
    }
  }

  static async getScheduleDays({ month, sourceCancel }) {
    try {
      return await axios.get(
        `${API.BASE_URL}/schedule/available/days/${month}`,
        {
          cancelToken: sourceCancel.token,
        }
      );
    } catch (error) {
      if (axios.isCancel(error)) {
        console.warn(
          `Requisição cancelada${error?.message ? `: ${error?.message}` : '.'}`
        );
      } else {
        console.warn('Erro na consulta dos dias disponíveis: ', error);
      }
    }
  }

  static async getScheduleHours({ month, day, sourceCancel }) {
    try {
      return await axios.get(
        `${API.BASE_URL}/schedule/available/hours/${month}/${day}`,
        {
          cancelToken: sourceCancel.token,
        }
      );
    } catch (error) {
      if (axios.isCancel(error)) {
        console.warn(
          `Requisição cancelada${error?.message ? `: ${error?.message}` : '.'}`
        );
      } else {
        console.warn('Erro na consulta dos horários disponíveis: ', error);
      }
    }
  }

  static async setSchedule({ token, reserveId, schedId }) {
    try {
      return await axios.post(
        `${API.BASE_URL}/schedule/${token}/${reserveId}/${schedId}`,
        {}
      );
    } catch (error) {
      console.warn('Erro no agendamento: ', error);
    }
  }
}
