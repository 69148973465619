import { useEffect, useContext, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import axios from 'axios';

import { makeStyles, Grid, Typography, Paper, Fade, CircularProgress } from '@material-ui/core';
import RecceiptIcon from '@material-ui/icons/Receipt';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { Link } from 'react-router-dom';

import Header from '../../components/header/Header';
import HeaderLinks from '../../components/header/HeaderLinks';
import GridContainer from '../../components/grid/GridContainer';
import Card from '../../components/card/Card';
import CardHeader from '../../components/card/CardHeader';
import CardBody from '../../components/card/CardBody';
import CardFooter from '../../components/card/CardFooter';
import Button from '../../components/custom-buttons/Button.js';
import Footer from '../../components/footer/Footer';
import SnackbarContent from '../../components/snackbar/SnackbarContent';

import { session } from '../../services/storage';
import ReserveServices from '../../services/reserve/reserve-service';
import ProductsServices from '../../services/products/products-services';
import { AuthContext } from '../../services/auth/auth-context';
import { GLOBAL } from '../../constants';

import styles from '../../assets/jss/material-kit-react/views/reservePageStyle.js';
import logo from 'assets/img/ds_logo_write.png';
import logoalt from 'assets/img/ds_logo_wine.png';

const useStyles = makeStyles(styles);

export default function ReservePage() {
  const history = useHistory();
  const { backurl } = useParams();
  const classes = useStyles();
  const { state, signOut, updateUser, setFromMobile } = useContext(AuthContext);
  const [sessionkey, setSessionkey] = useState(null);
  const [reservationId, setReservationId] = useState(null);
  const [plans, setPlans] = useState([]);
  const [reserve, setReserve] = useState(null);
  const [form, setForm] = useState({});
  const [notification, setNotification] = useState();
  const [showReserve, setShowReserve] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const changeForm = useCallback(
    (reserveDetails) => {
      if (Object.prototype.hasOwnProperty.call(reserveDetails, 'reservationId')) {
        const { name, qty, price, description } =
          plans.filter((plan) => {
            return plan?.sku === reserveDetails?.sku && plan?.qty === reserveDetails?.qty;
          })[0] || {};
        const reserveState =
          reserveDetails?.reserveState === 2
            ? { status: 'Pedido confirmado', color: '#4caf50' } //TODO: Colocar no CSS
            : { status: 'Pedido em processamento', color: '#ff9800' };
        const paymentState =
          reserveDetails?.paymentState === 3
            ? { status: 'Pagamento confirmado', color: '#4caf50' }
            : { status: 'Aguardando pagamento', color: '#f44336' };
        const method = reserveDetails?.method === 'payandgo' ? 'Retirada no local' : 'Correios';
        const {
          zip_code,
          addr_city,
          addr_state,
          addr_street_name,
          addr_street_number,
          addr_street_neighborhood,
          addr_street_details,
        } = reserveDetails?.method === 'payandgo' ? GLOBAL.PAYANDGO.ADDRESS : state?.user?.personal;
        const address = {
          zip_code,
          addr_city,
          addr_state,
          addr_street_name,
          addr_street_number,
          addr_street_neighborhood,
          addr_street_details,
        };

        setForm((form) => ({
          ...form,
          reserveId: reserveDetails?.id,
          date:
            reserveDetails?.timestamp &&
            new Date(reserveDetails?.timestamp * 1000).toLocaleDateString('pt-BR'),
          reserveState,
          paymentState,
          method,
          plan: { name, qty, price, description },
          address,
          payment: {
            date_approved:
              reserveDetails?.mpPaymentInfo?.date_approved &&
              new Date(reserveDetails?.mpPaymentInfo?.date_approved).toLocaleDateString('pt-br'),
            method:
              reserveDetails?.mpPaymentInfo?.payment_method_id &&
              paymentMethod(reserveDetails?.mpPaymentInfo?.payment_method_id),
            amount: formatNumber(reserveDetails?.mpPaymentInfo?.transaction_amount),
          },
          summary: {
            plan: formatNumber(
              parseFloat(
                parseInt(reserveDetails?.qty || 0) * parseFloat(reserveDetails?.unit_price || 0)
              )
            ),
            freight: formatNumber(reserveDetails?.delivery_tax || 0),
            total: formatNumber(
              parseFloat(
                parseInt(reserveDetails?.qty || 0) * parseFloat(reserveDetails?.unit_price || 0)
              ) + parseFloat(reserveDetails?.delivery_tax || 0)
            ),
          },
        }));
      } else {
        setForm({});
      }
    },
    [plans, state?.user?.personal]
  );

  const getScheduleDetails = useCallback(async (token) => {
    try {
      const resSchedule = await ReserveServices.getSchedule({ token });
      const scheduleDetails = resSchedule?.data;
      let scheduleDate = null;

      if (Object.prototype.hasOwnProperty.call(scheduleDetails, 'schedule')) {
        const sD = scheduleDetails.schedule[0];
        scheduleDate = `${sD.year}/${sD.month}/${sD.day} ${sD.hour}:${sD.minute}`;
      }

      setReserve((reserve) => {
        return { ...reserve, scheduleDetails };
      });

      setForm((form) => {
        return {
          ...form,
          scheduleDate,
        };
      });
    } catch (error) {
      console.log('Erro ao buscar agendamentos: ', error);
    }
  }, []);

  const getReserveDetails = useCallback(
    async ({ token, reserveId }) => {
      setIsLoading(true);
      try {
        if (token && reserveId) {
          await ReserveServices.getReserve({
            token,
            reserveId,
          })
            .then(async (res) => {
              const reserveDetails = {
                ...res.data,
                mpPaymentInfo: JSON.parse(res.data?.mpPaymentInfo) || {},
                mpPreferences: JSON.parse(res.data?.mpPreferences) || {},
              };
              setReserve(reserveDetails);
              changeForm(reserveDetails);
              delay(3000).then(() => setIsLoading(false));
            })
            .catch((error) => {
              console.log('Erro ao buscar reserva: ', error);
              setReserve({});
              changeForm({});
              setIsLoading(false);
            });
        } else {
          setReserve({});
          changeForm({});
          setIsLoading(false);
        }
      } catch (error) {
        setReserve({});
        changeForm({});
        setIsLoading(false);
        console.log('Erro ao buscar reserva: ', error);
      }
    },
    [changeForm]
  );

  const loadPlans = useCallback(async (sourceCancel) => {
    try {
      const response = await ProductsServices.getPlans(sourceCancel);
      setPlans(response?.data || []);
    } catch (error) {
      console.log('Erro ao carregar planos: ', error);
      throw error;
    }
  }, []);

  useEffect(() => {
    // failure, pending, success
    let message = null;
    if (backurl && reserve) {
      if (backurl === 'success' && reserve?.paymentState !== 3) {
        message = 'Sua reserva está em PROCESSAMENTO. Aguarde notificação por e-mail.';
        setShowReserve(false);
        setNotification((notification) => {
          return {
            ...notification,
            message,
            close: false,
            color: 'info',
            icon: 'info_outline',
          };
        });
      } else if (backurl === 'success' && reserve?.paymentState === 3) {
        message = 'Sua reserva está processada e pagamento concluído.';
        setShowReserve(true);
        setNotification((notification) => {
          return {
            ...notification,
            message,
            close: true,
            color: 'success',
            icon: 'info_outline',
          };
        });
      } else if (backurl === 'pending' && reserve?.paymentState < 3) {
        message = 'Sua reserva está pendente.';
        switch (reserve?.paymentState) {
          case 0:
            message = `${message} Não foi efetuado um pagamento ou aguardando o processamento.`;
            break;
          case 1:
            message = `${message} Ainda aguardando o processamento do pagamento.`;
            break;
          case 2:
            message = `${message} Houve uma falha, por favor tente novamente.`;
            break;
          default:
            break;
        }
        setShowReserve(true);
        setNotification((notification) => {
          return {
            ...notification,
            message,
            close: true,
            color: 'warning',
            icon: 'info_outline',
          };
        });
      } else if (backurl === 'failure' && reserve?.paymentState < 3) {
        message = 'Houve um ERRO.';
        switch (reserve?.paymentState) {
          case 0:
            message = `${message} Não foi efetuado um pagamento.`;
            break;
          case 1:
            message = `${message} Ainda aguardando o processamento do pagamento.`;
            break;
          case 2:
            message = `${message} Houve uma falha, por favor tente novamente.`;
            break;
          default:
            break;
        }
        setShowReserve(true);
        setNotification((notification) => {
          return {
            ...notification,
            message,
            close: true,
            color: 'danger',
            icon: 'info_outline',
          };
        });
      }
    }
  }, [backurl, reserve]);

  useEffect(() => {
    const user = state?.user || null;
    if (user) {
      const reserve = user?.reserve || null;
      if (reserve) {
        setSessionkey(user?.sessionkey || null);
        setReservationId(user?.reserve[0]?.reservationId || null);
      } else {
        setSessionkey(null);
        setReservationId(null);
      }
    }
    return () => {
      setSessionkey(null);
      setReservationId(null);
    };
  }, [state?.user]);

  useEffect(() => {
    const _reservationId = reserve?.reservationId || null;
    if (!_reservationId || _reservationId !== reservationId) {
      sessionkey &&
        reservationId &&
        getReserveDetails({ token: sessionkey, reserveId: reservationId });
    }
  }, [getReserveDetails, reservationId, reserve?.reservationId, sessionkey]);

  useEffect(() => {
    const method = reserve?.method || null;
    const scheduleDetails = reserve?.scheduleDetails || null;

    method && method === 'payandgo' && !scheduleDetails && getScheduleDetails(sessionkey);

    // return () => {}
  }, [getScheduleDetails, reserve?.method, reserve?.scheduleDetails, sessionkey]);

  useEffect(() => {
    session.setItem('plans', plans);
  }, [plans]);

  useEffect(() => {
    const cancelPlans = axios.CancelToken.source();
    const plansOfStorage = session.getItem('plans');

    if (plansOfStorage && plansOfStorage.length > 0) {
      setPlans(plansOfStorage);
    } else {
      loadPlans(cancelPlans);
    }

    return () => cancelPlans.cancel();
  }, [loadPlans]);

  useEffect(() => {
    const isFromMobile = session.getItem('isFromMobile') || false;
    !state.isFromMobile && isFromMobile && setFromMobile(isFromMobile);
  }, [setFromMobile, state.isFromMobile]);

  const checkSession = () => {
    const sessionkey = session.getItem('sessionkey') || null;
    if (state?.userToken !== sessionkey) {
      signOut();
    }
  };

  const paymentMethod = (id) => {
    let payment = '';
    switch (id) {
      case 'master':
        payment = 'Mastercard';
        break;
      case 'visa':
        payment = 'Visa';
        break;
      case 'hipercard':
        payment = 'Hipercard';
        break;
      case 'elo':
        payment = 'Elo';
        break;
      case 'bolbradesco':
        payment = 'Boleto Bancário';
        break;
      case 'melicard':
        payment = 'Cartão Mercado Livre';
        break;
      case 'account_money':
        payment = 'Dinheiro em conta';
        break;
      case 'paypal':
        payment = 'Paypal';
        break;
      case 'pix':
        payment = 'Pix';
        break;
      default:
        payment = 'Outra forma de pagamento';
        break;
    }
    return payment;
  };

  const formatNumber = (numFloat) => {
    return parseFloat(String(numFloat).toString().replace(',', '.')).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  const handleCancel = async (event) => {
    event.preventDefault();
    try {
      checkSession();
      if (!Object.prototype.hasOwnProperty.call(reserve?.mpPaymentInfo, 'payment_method_id')) {
        if (sessionkey && reservationId) {
          const responseCancel = await ReserveServices.delReserve({
            token: sessionkey,
            reserveId: reservationId,
          });
          if (responseCancel?.status === 200 && responseCancel?.data?.result === 'ok_canceled') {
            updateUser(sessionkey).then(() => {
              history.push('/');
            });
            alert('Pedido cancelado com sucesso!');
          }
        }
      }
    } catch (error) {
      console.log('Erro ao efetuar cancelamento : ', error);
      updateUser(sessionkey).then(() => {
        history.push('/');
      });
      throw error;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      checkSession();
      if (
        !Object.prototype.hasOwnProperty.call(reserve?.mpPaymentInfo, 'payment_method_id') &&
        Object.prototype.hasOwnProperty.call(reserve?.mpPreferences, 'body')
      ) {
        const { init_point } = reserve?.mpPreferences?.body;
        window.location.href = String(init_point);
      }
    } catch (error) {
      console.log('Erro ao efetuar pagamento : ', error);
      throw error;
    }
  };

  return (
    <div>
      {!state.isFromMobile && (
        <Header
          absolute
          color="secondary"
          brand="DriveSocial"
          logo={logo}
          logoalt={logoalt}
          rightLinks={
            <HeaderLinks
              cart
              user={{
                uid: state?.user?.uid || null,
                name: state?.user?.personal?.name || state?.user?.fullname || 'Sem nome',
                surname: state?.user?.personal?.surname || '',
                email: state?.user?.email,
              }}
            />
          }
          isCart="true"
        />
      )}
      <div className={classes.pageHeader}>
        <div className={!state.isFromMobile ? classes.container : classes.containerMobile}>
          <GridContainer justify="center">
            {isLoading && (
              <div className={classes.loading}>
                <Fade in={isLoading} unmountOnExit>
                  <CircularProgress color="secondary" />
                </Fade>
              </div>
            )}

            {!isLoading && notification && (
              <SnackbarContent
                message={<span>{notification?.message}</span>}
                close={notification?.close}
                color={notification?.color}
                icon={notification?.icon}
              />
            )}
            {!isLoading && reservationId && reserve?.reservationId && showReserve && (
              <Card className={classes.card}>
                <CardHeader color="primary" className={classes.cardHeader}>
                  <Typography variant="subtitle1">Você possui um pedido em andamento</Typography>
                </CardHeader>
                <CardBody>
                  <Grid item container xs direction="row" spacing={2}>
                    <Grid item container xs={12} sm={12} md={6} direction="column" spacing={0}>
                      <Paper className={classes.paper} elevation={1}>
                        <Grid
                          item
                          xs
                          style={{
                            textAlign: 'center',
                            verticalAlign: 'middle',
                          }} //TODO: Colocar no CSS
                        >
                          <RecceiptIcon
                            fontSize="large"
                            style={{
                              verticalAlign: 'middle',
                              margin: '5px',
                              color: form?.reserveState?.color,
                            }} //TODO: Colocar no CSS
                          />
                          <span
                            style={{ color: form?.reserveState?.color }} //TODO: Colocar no CSS
                          >
                            <b>{form?.reserveState?.status}</b>
                          </span>
                        </Grid>
                        <Grid item xs>
                          <span className={classes.label}>Data do pedido:</span>
                          {form?.date}
                        </Grid>
                        <Grid item xs>
                          <span className={classes.label}>Plano solicitado:</span>
                          {form?.plan?.name}
                        </Grid>
                        <Grid item xs>
                          <span className={classes.label}>Valor:</span>
                          {form?.summary?.plan}
                        </Grid>
                        <Grid item xs>
                          <span className={classes.label}>Forma de entrega:</span>
                          {form?.method}
                        </Grid>
                        {reserve?.delivery_tax && (
                          <Grid item xs>
                            <span className={classes.label}>Valor de entrega:</span>
                            {form?.summary?.freight}
                          </Grid>
                        )}
                        <Grid item xs>
                          <span className={classes.label}>
                            Endereço de
                            {reserve?.method === 'payandgo' ? ' retirada' : ' entrega'}:
                          </span>
                          <br />
                          &nbsp;&nbsp;
                          {form?.address?.addr_street_name},&nbsp;
                          {form?.address?.addr_street_number}&nbsp;-&nbsp;
                          {form?.address?.addr_street_details}&nbsp;-&nbsp;
                          {form?.address?.addr_street_neighborhood}
                          &nbsp;-&nbsp;
                          {form?.address?.addr_city}/{form?.address?.addr_state}
                        </Grid>
                        {form?.scheduleDate && (
                          <Grid item xs>
                            <span className={classes.label}>Data e hora para retirada:</span>
                            <b>
                              <span
                                className={classes.label}
                                style={{ color: form?.reserveState?.color }} //TODO: Colocar no CSS
                              >
                                {new Date(form?.scheduleDate).toLocaleDateString('pt-BR', {
                                  year: 'numeric',
                                  month: 'numeric',
                                  weekday: 'long',
                                  day: 'numeric',
                                  hour: 'numeric',
                                  minute: 'numeric',
                                  hour12: false,
                                })}
                              </span>
                            </b>
                          </Grid>
                        )}

                        {!form?.scheduleDate && reserve?.method === 'payandgo' && (
                          <Grid item xs style={{ textAlign: 'center' }}>
                            <span
                              className={classes.label}
                              style={{ color: 'red' }} //TODO: Colocar no CSS
                            >
                              Agendamento ainda não realizado.
                            </span>
                          </Grid>
                        )}
                      </Paper>
                    </Grid>

                    <Grid item container xs={12} sm={12} md={6} direction="column" spacing={0}>
                      <Paper className={classes.paper} elevation={1}>
                        <Grid
                          item
                          xs
                          style={{
                            textAlign: 'center',
                            verticalAlign: 'middle',
                          }} //TODO: Colocar no CSS
                        >
                          <CreditCardIcon
                            fontSize="large"
                            style={{
                              verticalAlign: 'middle',
                              margin: '5px',
                              color: form?.paymentState?.color,
                            }} //TODO: Colocar no CSS
                          />
                          <span
                            style={{ color: form?.paymentState?.color }} //TODO: Colocar no CSS
                          >
                            <b>{form?.paymentState?.status}</b>
                          </span>
                        </Grid>
                        <Grid item xs>
                          <span className={classes.label}>Valor total:</span>
                          {form?.summary?.total}
                        </Grid>
                        <Grid item xs>
                          <span className={classes.label}>Data do pagamento:</span>
                          {form?.payment?.date_approved}
                        </Grid>
                        <Grid item xs>
                          <span className={classes.label}>Forma de pagamento:</span>
                          {form?.payment?.method}
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </CardBody>
                {!Object.prototype.hasOwnProperty.call(
                  reserve?.mpPaymentInfo,
                  'payment_method_id'
                ) && (
                  <CardFooter className={classes.cardFooter}>
                    <Button color="danger" onClick={handleCancel}>
                      Cancelar Pedido
                    </Button>
                    {(reserve?.paymentState !== 1 ||
                      reserve?.paymentState !== 3 ||
                      backurl === 'pending') && (
                      <Button color="success" onClick={handleSubmit}>
                        Efetuar Pagamento
                      </Button>
                    )}
                  </CardFooter>
                )}
                {!form?.scheduleDate &&
                  reserve?.method === 'payandgo' &&
                  reserve?.reserveState === 2 &&
                  reserve?.paymentState === 3 && (
                    <CardFooter className={classes.cardFooter}>
                      <Button
                        color="primary"
                        component={Link}
                        to={{
                          pathname: '/schedule',
                          state: { fromReserve: true },
                        }}
                      >
                        <ScheduleIcon className={classes.icons} />
                        Agendar
                      </Button>
                    </CardFooter>
                  )}
              </Card>
            )}
          </GridContainer>
        </div>
        {!state.isFromMobile && <Footer />}
      </div>
    </div>
  );
}
