import axios from 'axios';

import { API } from '../../constants';

export default class OrderService {
  static async setPersonal(token, personal) {
    try {
      return await axios.post(
        `${API.BASE_URL}/personal/${token}`,
        // "https://checkout-api.drivesocial.io/personal/" + token,
        personal
      );
    } catch (error) {
      console.warn('Erro na atualização do usuário: ', error);
      throw error;
    }
  }

  static async setRevervation({ token, sku, method, qty, freight }) {
    try {
      return await axios.post(`${API.BASE_URL}/reserve/${token}/${sku}/${method}/${qty}`, {
        freight,
        source: 'checkout',
      });
    } catch (error) {
      console.warn('Erro na reserva do plano: ', error);
      throw error;
    }
  }
}
