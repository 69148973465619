import { GLOBAL } from '../constants';

const prefix = GLOBAL.STORAGE.PREFIX; //"@ds-price";

export const local = {
  //   getItem: async (item) => {
  //     return await localStorage.getItem("@ds-price_cart");
  //   },
  //   setItem: async (item, value) => {
  //     // await localStorage.setItem(prefix + "_" + item, JSON.stringify(value));
  //     await localStorage.setItem("@ds-price_cart", JSON.stringify(value));
  //   },
  getItem: (item) => {
    return JSON.parse(localStorage.getItem(`${prefix}_${item}`));
  },
  setItem: (item, value) => {
    localStorage.setItem(`${prefix}_${item}`, JSON.stringify(value));
  },
  removeItem: (item) => {
    localStorage.removeItem(`${prefix}_${item}`);
  },
  clear: () => {
    localStorage.clear();
  },
};

export const session = {
  getItem: (item) => {
    return JSON.parse(sessionStorage.getItem(`${prefix}_${item}`));
  },
  setItem: (item, value) => {
    sessionStorage.setItem(`${prefix}_${item}`, JSON.stringify(value));
  },
  removeItem: (item) => {
    sessionStorage.removeItem(`${prefix}_${item}`);
  },
  clear: () => {
    sessionStorage.clear();
  },
};
