import {
  container,
  title,
  // primaryColor,
} from '../../../../assets/jss/material-kit-react.js';

const cartPageStyle = {
  container: {
    ...container,
    zIndex: '2',
    position: 'relative',
    paddingTop: '20vh',
    color: '#FFFFFF',
    paddingBottom: '200px',
  },
  containerMobile: {
    ...container,
    zIndex: '2',
    position: 'relative',
    paddingTop: '5vh',
    color: '#FFFFFF',
    paddingBottom: '10vh',
  },
  //   title: {
  //     ...title,
  //     display: "inline-block",
  //     position: "relative",
  //     marginTop: "30px",
  //     minHeight: "32px",
  //     color: "#FFFFFF",
  //     textDecoration: "none",
  //   },
  title: {
    ...title,
    marginTop: '8px',
    minHeight: '32px',
    textDecoration: 'none',
  },
  subtitle: {
    fontSize: '1.313rem',
    maxWidth: '500px',
    margin: '10px auto 0',
  },
  main: {
    background: '#FFFFFF',
    position: 'relative',
    zIndex: '3',
  },
  mainRaised: {
    margin: '-60px 30px 0px',
    borderRadius: '6px',
    boxShadow:
      '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
  },
  pageHeader: {
    minHeight: '100vh',
    height: 'auto',
    display: 'inherit',
    position: 'relative',
    margin: '0',
    padding: '0',
    border: '0',
    alignItems: 'center',
    '&:before': {
      // background: "rgba(0, 0, 0, 0.5)",
      background: '#f2f2f2',
    },
    '&:before,&:after': {
      position: 'absolute',
      zIndex: '1',
      width: '100%',
      height: '100%',
      display: 'block',
      left: '0',
      top: '0',
      content: '""',
    },
    '& footer li a,& footer li a:hover,& footer li a:active': {
      // color: "#FFFFFF",
    },
    '& footer': {
      position: 'absolute',
      bottom: '0',
      width: '100%',
    },
  },
  paper: {
    padding: '10px',
    // textAlign: "center",
    marginBottom: '10px',
    // color: theme.palette.text.secondary,
  },
  paperLoading: {
    padding: '10px',
    marginBottom: '10px',
    backgroundColor: '#ccc',
  },
  root: {
    // display: "flex",
    // flexWrap: "wrap",
    // margin: "10px",
  },
  textField: {
    // marginLeft: "5px",
    // marginRight: "5px",
    // width: "25ch",
  },
  productName: {
    width: '100%',
  },
  productNumber: {
    width: '100%',
    textAlign: 'center',
  },
  productValue: {
    width: '100%',
  },
  resumeLoading: {
    // height: 40,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
    // display: "flex",
  },
  resumeItem: {
    marginTop: '4px',
    marginBottom: '4px',
  },
  resumeButton: {
    textAlign: 'center',
  },
  label: {
    cursor: 'pointer',
    paddingLeft: '0',
    color: '#3c4858', //"rgba(0, 0, 0, 0.87)",
    fontSize: '16px',
    lineHeight: '1.428571429',
    fontWeight: '400',
    display: 'inline-flex',
    transition: '0.3s ease all',
    letterSpacing: 'unset',
  },
  switchBase: {
    // color: primaryColor + "!important",
    color: '#880e4f !important',
  },
  switchIcon: {
    boxShadow: '0 1px 3px 1px rgba(0, 0, 0, 0.4)',
    color: '#FFFFFF !important',
    border: '1px solid rgba(0, 0, 0, .54)',
  },
  switchBar: {
    width: '30px',
    height: '15px',
    backgroundColor: 'rgb(80, 80, 80)',
    borderRadius: '15px',
    opacity: '0.7!important',
  },
  switchChecked: {
    '& + $switchBar': {
      // backgroundColor: 'rgba(156, 39, 176, 1) !important',
      backgroundColor: '#880e4f !important',
    },
    '& $switchIcon': {
      // borderColor: "#9c27b0",
      borderColor: '#880e4f',
    },
  },
  switchRoot: {
    height: '48px',
  },
  // GridItem: {
  //   "& gridItemZIP": {
  //     marginTop: "16px",
  //     marginBottom: "8px",
  //   },
  // },
};

export default cartPageStyle;
