import { useEffect, useCallback, useContext, useState } from 'react';

import axios from 'axios';

import {
  makeStyles,
  Paper,
  Box,
  InputAdornment,
  Divider,
  Fade,
  CircularProgress,
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { validate } from 'gerador-validador-cpf';

import Header from '../../components/header/Header';
import HeaderLinks from '../../components/header/HeaderLinks';
import GridContainer from '../../components/grid/GridContainer';
import GridItem from '../../components/grid/GridItem';
import Footer from '../../components/footer/Footer';
import CustomInput from '../../components/custom-input/CustomInput';
import Button from '../../components/custom-buttons/Button';

import { AuthContext } from '../../services/auth/auth-context';
import ZipServices from '../../services/cart/zip-services';
import OrderService from '../../services/cart/order-service';
import { session } from '../../services/storage';

import styles from '../../assets/jss/material-kit-react/views/orderPageStyle.js';
import logo from 'assets/img/ds_logo_write.png';
import logoalt from 'assets/img/ds_logo_wine.png';

const useStyles = makeStyles(styles);

export default function OrderPage(props) {
  const classes = useStyles();
  const location = useLocation();
  const { state, signOut, updateUser } = useContext(AuthContext);
  const { cart } = location?.state;
  const [status, setStatus] = useState({
    formIsOk: false,
    calculating: 'idle',
  });
  const [productAvailable, setProductAvailable] = useState({});
  const [user, setUser] = useState({});
  const [form, setForm] = useState({
    name: '',
    surname: '',
    email: '',
    phone: '',
    id_type: 'CPF',
    id_data: '',
    zip_code: '',
    addr_street_name: '',
    addr_street_number: '',
    addr_street_details: '',
    addr_street_neighborhood: '',
    addr_city: '',
    addr_state: '',
  });
  const [isDisabled, setIsDisabled] = useState({
    email: false,
    zip_code: false,
    addr_street_name: false,
    addr_street_neighborhood: false,
    addr_city: false,
    addr_state: false,
  });
  const [erros, setErros] = useState({
    name: true,
    surname: true,
    email: true,
    id_data: true,
    zip_code: true,
    addr_street_name: true,
    addr_street_number: true,
    addr_street_neighborhood: true,
    addr_city: true,
    addr_state: true,
  });

  const checkSession = useCallback(async () => {
    const sessionkey = session.getItem('sessionkey') || null;
    if (state?.userToken !== sessionkey) {
      signOut();
    }
  }, [signOut, state?.userToken]);

  const calculateDeliveryTime = useCallback(
    async ({ days, sourceCancel }) => {
      const sku = cart?.plan?.sku || null;
      const method = cart?.payAndGo ? 'payandgo' : 'ship';
      const qty = cart?.plan?.qty || null;
      const payAndGo = cart?.payAndGo;

      if (days && sku && method && qty) {
        changeStatusCalculating('progress');
        const response = await ZipServices.getDispatchTime({
          sku,
          method,
          qty,
          sourceCancel,
        });
        const plus = response?.data?.estimate || 1;
        let deliveryTime = plus > 1 ? `${plus} dias úteis` : `${plus} dia útil`;
        !payAndGo && (deliveryTime = `${parseInt(days) + parseInt(plus)} dias úteis`);
        setProductAvailable({
          ...response?.data,
          deliveryTime,
        });
        changeStatusCalculating('success');
      } else {
        setProductAvailable({
          deliveryTime: ' indefinido',
        });
      }
    },
    [cart?.payAndGo, cart?.plan?.qty, cart?.plan?.sku]
  );

  useEffect(() => {
    if (state?.isAuthenticated) {
      checkSession();
    } else {
      signOut();
    }
  }, [checkSession, signOut, state?.isAuthenticated]);

  useEffect(() => {
    setUser(state.user);
  }, [state]);

  useEffect(() => {
    const cancelDeliveryTime = axios.CancelToken.source();
    const days = cart?.costFromCorreios?.PrazoEntrega || null;
    days && calculateDeliveryTime({ days, sourceCancel: cancelDeliveryTime });

    return () => cancelDeliveryTime.cancel();
  }, [calculateDeliveryTime, cart?.costFromCorreios?.PrazoEntrega]);

  useEffect(() => {
    const personal = user?.personal || null;

    if (personal) {
      if (
        String(cart?.address?.zip_code).replace(/[^\d]/g, '') ===
        String(user?.personal?.zip_code).replace(/[^\d]/g, '')
      ) {
        fillFields({
          ...user.personal,
        });
      } else {
        fillFields({
          ...user.personal,
          ...cart?.address,
        });
      }
    } else {
      fillFields({
        ...cart?.address,
        name: user.fullname,
        email: user.email,
      });
    }
  }, [cart?.address, user.email, user.fullname, user.personal]);

  useEffect(() => {
    let count = 0;
    Object.keys(form).map((key) => {
      if (Object.prototype.hasOwnProperty.call(erros, key) && erros[key]) {
        count += 1;
      }
      return null;
    });
    if (count > 0) {
      setStatus((status) => {
        return {
          ...status,
          formIsOk: false,
        };
      });
    } else {
      setStatus((status) => {
        return {
          ...status,
          formIsOk: true,
        };
      });
    }
  }, [erros, form]);

  const fillFields = (obj) => {
    Object.keys(obj).map((key) => {
      const value = String(obj[key]);
      if (value.length > 0) {
        setForm((form) => {
          return {
            ...form,
            [key]: value,
          };
        });
        setIsDisabled((isDisabled) => {
          return {
            ...isDisabled,
            [key]: true,
          };
        });
        setErros((erros) => {
          return {
            ...erros,
            [key]: false,
          };
        });
      }
      return null;
    });
  };

  const changeErros = (field, value) => {
    if (Object.prototype.hasOwnProperty.call(erros, field)) {
      setErros((erros) => {
        return {
          ...erros,
          [field]: value,
        };
      });
    }
  };

  const isInvalidValue = (value) => {
    // return value.trim() === "" || !/^[a-záàâãéèêíïóôõöúçñ ]+$/i.test(value);
    return value.trim() === '' || value.length === 0;
  };

  const goToPaywall = ({ orderResponse }) => {
    if (orderResponse?.status === 200 || orderResponse?.status === 201) {
      const { init_point } = orderResponse?.response;
      window.location.href = String(init_point);
    }
  };

  const changeStatusCalculating = (calculating) => {
    setStatus((status) => {
      return {
        ...status,
        calculating,
      };
    });
  };

  const formatNumber = (numFloat) => {
    return parseFloat(String(numFloat).toString().replace(',', '.')).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  const handleFormField = (event) => {
    const target = event.target;
    const field = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    setForm((form) => {
      return {
        ...form,
        [field]: value,
      };
    });
    if (isInvalidValue(value)) {
      changeErros(field, true);
    } else if (field === 'id_data') {
      changeErros(field, !validate(value));
    } else {
      changeErros(field, false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await checkSession();
      const token = state?.userToken || session.getItem('sessionkey');
      const { dateCreated, dateModified, ...personal } = form;
      const resPersonal = (await OrderService.setPersonal(token, personal)) || {};
      const reservation = {
        token,
        sku: cart?.plan?.sku,
        method: cart?.payAndGo ? 'payandgo' : 'ship',
        qty: cart?.plan?.qty,
        freight: parseFloat(cart?.summary?.freight),
      };
      const resReservation =
        resPersonal?.status === 200 && (await OrderService.setRevervation(reservation));

      if (resReservation.status === 200) {
        updateUser(token);
        // session.setItem('reservation', resReservation?.data);
        goToPaywall(resReservation?.data);
      }
    } catch (error) {
      console.log('Erro ao enviar pedido: ', error);
      throw error;
    }
  };

  return (
    <div>
      {!state.isFromMobile && (
        <Header
          absolute
          color="secondary"
          brand="DriveSocial"
          logo={logo}
          logoalt={logoalt}
          rightLinks={
            <HeaderLinks
              cart
              user={{
                uid: state?.user?.uid || null,
                name: state?.user?.personal?.name || state?.user?.fullname || 'Sem nome',
                surname: state?.user?.personal?.surname || '',
                email: state?.user?.email,
              }}
            />
          }
          isCart="true"
        />
      )}
      <div className={classes.pageHeader}>
        <div className={!state.isFromMobile ? classes.container : classes.containerMobile}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8}>
              <Paper className={classes.paper} elevation={1}>
                <Box component="span" color="textFloat.primary">
                  <h4 className={classes.title}>Complete seus dados para continuar</h4>
                </Box>
                <form className={classes.root} noValidate autoComplete="off">
                  <GridContainer justify="space-around" className={classes.resumeItem}>
                    <GridItem xs={12} sm={6} md={6} lg={6}>
                      <CustomInput
                        labelText="Nome"
                        id="name"
                        name="name"
                        value={form.name || ''}
                        error={erros.name}
                        onChange={handleFormField}
                        labelProps={{
                          required: erros.name,
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          required: true,
                          // disabled: isDisabled.name,
                          endAdornment: (
                            <InputAdornment position="end">
                              <i className="fas fa-user" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} lg={6}>
                      <CustomInput
                        labelText="Sobrenome"
                        id="surname"
                        name="surname"
                        value={form.surname || ''}
                        error={erros.surname}
                        onChange={handleFormField}
                        labelProps={{
                          required: erros.surname,
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          required: true,
                          // disabled: isDisabled.surname,
                          endAdornment: (
                            <InputAdornment position="end">
                              <i className="fas fa-users" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify="space-around" className={classes.resumeItem}>
                    <GridItem xs={12} sm={6} md={6} lg={6}>
                      <CustomInput
                        labelText="Email"
                        id="email"
                        name="email"
                        value={form.email || ''}
                        error={erros.email}
                        onChange={handleFormField}
                        labelProps={{
                          required: erros.email,
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          required: true,
                          disabled: isDisabled.email,
                          endAdornment: (
                            <InputAdornment position="end">
                              <i className="fas fa-at" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} lg={6}>
                      <InputMask
                        mask="(99) 99999-9999"
                        maskChar=" "
                        maskPlaceholder={null}
                        value={form.phone || ''}
                        onChange={handleFormField}
                      >
                        {(inputProps) => (
                          <CustomInput
                            {...inputProps}
                            labelText="Celular"
                            id="phone"
                            name="phone"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              // disabled: isDisabled.phone,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <i className="fas fa-mobile-alt" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      </InputMask>
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify="space-around" className={classes.resumeItem}>
                    <GridItem xs={12} sm={6} md={6} lg={6}>
                      <InputMask
                        mask="999.999.999-99"
                        maskChar=" "
                        maskPlaceholder={null}
                        value={form.id_data || ''}
                        onChange={handleFormField}
                      >
                        {(inputProps) => (
                          <CustomInput
                            {...inputProps}
                            labelText="CPF"
                            id="cpf"
                            name="id_data"
                            error={erros.id_data}
                            labelProps={{
                              required: erros.id_data,
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              required: true,
                              // disabled: isDisabled.id_data,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <i className="fas fa-address-card" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      </InputMask>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} lg={6}>
                      <CustomInput
                        labelText="CEP"
                        id="zipCode"
                        name="zip_code"
                        value={form.zip_code || ''}
                        error={erros.zip_code}
                        onChange={handleFormField}
                        labelProps={{
                          required: erros.zip_code,
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          required: true,
                          disabled: isDisabled.zip_code,
                          endAdornment: (
                            <InputAdornment position="end">
                              <i className="fas fa-envelope" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify="space-around" className={classes.resumeItem}>
                    <GridItem xs={8} sm={8} md={8} lg={8}>
                      <CustomInput
                        labelText="Endereço"
                        id="place"
                        name="addr_street_name"
                        value={form.addr_street_name || ''}
                        error={erros.addr_street_name}
                        onChange={handleFormField}
                        labelProps={{
                          required: erros.addr_street_name,
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          required: true,
                          disabled: isDisabled.addr_street_name,
                          endAdornment: (
                            <InputAdornment position="end">
                              <i className="fas fa-map" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4} lg={4}>
                      <CustomInput
                        labelText="Número"
                        id="placeNumber"
                        name="addr_street_number"
                        value={form.addr_street_number || ''}
                        error={erros.addr_street_number}
                        onChange={handleFormField}
                        labelProps={{
                          required: erros.addr_street_number,
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          required: true,
                          // disabled: isDisabled.addr_street_number,
                          endAdornment: (
                            <InputAdornment position="end">
                              <i className="fas fa-map-marker-alt" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify="space-around" className={classes.resumeItem}>
                    <GridItem xs={12} sm={5} md={5} lg={5}>
                      <CustomInput
                        labelText="Bairro"
                        id="neighborhood"
                        name="addr_street_neighborhood"
                        value={form.addr_street_neighborhood || ''}
                        error={erros.addr_street_neighborhood}
                        onChange={handleFormField}
                        labelProps={{
                          required: erros.addr_street_neighborhood,
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          required: true,
                          disabled: isDisabled.addr_street_neighborhood,
                          endAdornment: (
                            <InputAdornment position="end">
                              <i className="fas fa-street-view" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={7} md={7} lg={7}>
                      <CustomInput
                        labelText="Complemento"
                        id="complement"
                        name="addr_street_details"
                        value={form.addr_street_details || ''}
                        onChange={handleFormField}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          // disabled: isDisabled.addr_street_details,
                          endAdornment: (
                            <InputAdornment position="end">
                              <i className="fas fa-info-circle" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer justify="space-around" className={classes.resumeItem}>
                    <GridItem xs={12} sm={7} md={7} lg={7}>
                      <CustomInput
                        labelText="Cidade"
                        id="city"
                        name="addr_city"
                        value={form.addr_city || ''}
                        error={erros.addr_city}
                        onChange={handleFormField}
                        labelProps={{
                          required: erros.addr_city,
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          required: true,
                          disabled: isDisabled.addr_city,
                          endAdornment: (
                            <InputAdornment position="end">
                              <i className="far fa-map" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={5} md={5} lg={5}>
                      <CustomInput
                        labelText="Estado"
                        id="state"
                        name="addr_state"
                        value={form.addr_state || ''}
                        error={erros.addr_state}
                        onChange={handleFormField}
                        labelProps={{
                          required: erros.addr_state,
                        }}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          required: true,
                          disabled: isDisabled.addr_state,
                          endAdornment: (
                            <InputAdornment position="end">
                              <i className="fas fa-flag" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </form>
              </Paper>
            </GridItem>
            {/* Resumo */}
            <GridItem xs={12} sm={12} md={4}>
              <Paper
                className={status.calculating === 'progress' ? classes.paperLoading : classes.paper}
                elevation={1}
              >
                <div className={classes.resumeLoading}>
                  <Fade in={status.calculating === 'progress'} unmountOnExit>
                    <CircularProgress color="secondary" />
                  </Fade>
                </div>
                <Box component="span" color="textFloat.primary">
                  <h4 className={classes.title}>Resumo do pedido</h4>
                </Box>
                <GridContainer justify="space-around" className={classes.resumeItem}>
                  <GridItem xs={7} sm={7} md={7}>
                    {cart?.plan?.qty} plano{cart?.plan?.qty > 1 ? 's' : ''} Premium
                  </GridItem>
                  <GridItem xs={5} sm={5} md={5} style={{ textAlign: 'center' }}>
                    {formatNumber(cart?.summary?.plan)}
                  </GridItem>
                </GridContainer>
                <GridContainer justify="space-around" className={classes.resumeItem}>
                  <GridItem xs={7} sm={7} md={7}>
                    Frete
                  </GridItem>
                  <GridItem xs={5} sm={5} md={5} style={{ textAlign: 'center' }}>
                    {formatNumber(cart?.summary?.freight)}
                  </GridItem>
                </GridContainer>
                <Divider variant="inset" />
                <GridContainer justify="space-around" className={classes.resumeItem}>
                  <GridItem xs={7} sm={7} md={7}>
                    <b>Total</b>
                  </GridItem>
                  <GridItem xs={5} sm={5} md={5} style={{ textAlign: 'center' }}>
                    {/* // TODO: colocar no CSS */}
                    <b>{formatNumber(cart?.summary?.total)}</b>
                  </GridItem>
                </GridContainer>
                <br />
                <Divider variant="fullWidth" />
                <br />
                <GridContainer justify="space-around" className={classes.resumeItem}>
                  <GridItem xs={12} sm={12} md={12} style={{ textAlign: 'justify' }}>
                    {/* // TODO: colocar no CSS */}
                    Estimativa de {cart?.payAndGo ? 'agendamento' : 'entrega'} em{' '}
                    {productAvailable?.deliveryTime}
                  </GridItem>
                </GridContainer>
                {cart?.costFromCorreios?.obsFim && (
                  <div>
                    <br />
                    <Divider variant="fullWidth" />
                    <br />
                    <GridContainer justify="space-around" className={classes.resumeItem}>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        style={{ textAlign: 'justify', fontSize: '12px' }}
                      >
                        {/* // TODO: colocar no CSS */}
                        {'Obs.: ' + cart?.costFromCorreios?.obsFim}
                      </GridItem>
                    </GridContainer>
                  </div>
                )}
                <br />
                <Divider variant="fullWidth" />
                <br />
                <GridContainer justify="center" className={classes.resumeButton}>
                  <GridItem xs={12} sm={12} md={12}>
                    <Button
                      color="primary"
                      disabled={!status.formIsOk || status.calculating !== 'success'}
                      onClick={handleSubmit}
                      // component={Link}
                      // to={{
                      //   pathname: "/order",
                      //   state: { fromCart: true, cart },
                      // }}
                    >
                      Pagar
                    </Button>
                  </GridItem>
                </GridContainer>
              </Paper>
            </GridItem>
          </GridContainer>
        </div>
        {!state.isFromMobile && <Footer />}
      </div>
    </div>
  );
}
