import { useEffect, useContext, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import axios from 'axios';

import {
  makeStyles,
  Grid,
  Typography,
  InputLabel,
  Select,
} from '@material-ui/core';

import Header from '../../components/header/Header';
import HeaderLinks from '../../components/header/HeaderLinks';
import GridContainer from '../../components/grid/GridContainer';
import Card from '../../components/card/Card';
import CardHeader from '../../components/card/CardHeader';
import CardBody from '../../components/card/CardBody';
import CardFooter from '../../components/card/CardFooter';
import Button from '../../components/custom-buttons/Button.js';

import { session } from '../../services/storage';
import ReserveServices from '../../services/reserve/reserve-service';
import { AuthContext } from '../../services/auth/auth-context';
import { GLOBAL } from '../../constants';

import styles from '../../assets/jss/material-kit-react/views/schedulePageStyle.js';
import logo from 'assets/img/ds_logo_write.png';
import logoalt from 'assets/img/ds_logo_wine.png';

const useStyles = makeStyles(styles);

export default function SchedulePage() {
  const history = useHistory();
  const classes = useStyles();
  const { state, signOut, updateUser } = useContext(AuthContext);
  const [scheduleDate, setScheduleDate] = useState({});

  const checkSession = useCallback(() => {
    const sessionkey = session.getItem('sessionkey') || null;
    if (state?.userToken !== sessionkey) {
      signOut();
    }
  }, [signOut, state?.userToken]);

  const getHours = useCallback(async (month, day, sourceCancel) => {
    const response = await ReserveServices.getScheduleHours({
      month,
      day,
      sourceCancel,
    });
    response?.status === 200 &&
      setScheduleDate((scheduleDate) => {
        return {
          ...scheduleDate,
          hours: response?.data,
        };
      });
  }, []);

  const getDays = useCallback(async (month, sourceCancel) => {
    const response = await ReserveServices.getScheduleDays({
      month,
      sourceCancel,
    });
    response?.status === 200 &&
      setScheduleDate((scheduleDate) => {
        return {
          ...scheduleDate,
          days: response?.data,
        };
      });
  }, []);

  const getMonths = useCallback(async (sourceCancel) => {
    const response = await ReserveServices.getScheduleMonths(sourceCancel);
    response?.status === 200 &&
      setScheduleDate((scheduleDate) => {
        return {
          ...scheduleDate,
          months: response?.data,
        };
      });
  }, []);

  useEffect(() => {
    const cancelHours = axios.CancelToken.source();

    scheduleDate?.month &&
      scheduleDate?.day &&
      getHours(scheduleDate.month, scheduleDate.day, cancelHours);

    return () => cancelHours.cancel();
  }, [getHours, scheduleDate.month, scheduleDate.day]);

  useEffect(() => {
    const cancelDays = axios.CancelToken.source();

    scheduleDate?.month && getDays(scheduleDate.month, cancelDays);

    return () => cancelDays.cancel();
  }, [getDays, scheduleDate.month]);

  useEffect(() => {
    const cancelMonths = axios.CancelToken.source();

    checkSession();

    getMonths(cancelMonths);

    return () => cancelMonths.cancel();
  }, [checkSession, getMonths]);

  const handleScheduleMonth = async (event) => {
    const month = event.target.value;
    setScheduleDate((scheduleDate) => {
      const { day, hour, days, hours, schedule, ...rest } = scheduleDate;
      return {
        ...rest,
        month,
      };
    });
  };

  const handleScheduleDay = async (event) => {
    const day = event.target.value;
    setScheduleDate((scheduleDate) => {
      const { hour, hours, schedule, ...rest } = scheduleDate;
      return {
        ...rest,
        day,
      };
    });
  };

  const handleScheduleHour = (event) => {
    const hour = event.target.value;
    const key = parseInt(event.target.selectedIndex);
    if (Object.prototype.hasOwnProperty.call(scheduleDate, 'hours')) {
      const schedule = scheduleDate.hours[key - 1];
      setScheduleDate((scheduleDate) => {
        return {
          ...scheduleDate,
          schedule,
          hour,
        };
      });
    }
  };

  const handleSubmit = async () => {
    try {
      const token = state?.userToken;
      const reserveId =
        Object.prototype.hasOwnProperty.call(state?.user, 'reserve') &&
        state?.user?.reserve[0]?.reservationId;
      const schedId = scheduleDate?.schedule?.schedId;
      if (token && reserveId && schedId) {
        const responseSchedule = await ReserveServices.setSchedule({
          token,
          reserveId,
          schedId,
        });
        if (
          responseSchedule?.status === 200 &&
          responseSchedule?.data?.result
        ) {
          updateUser(token).then(() => {
            history.push('/');
          });
          alert('Agendamento realizado com sucesso!');
        }
      }
    } catch (error) {
      console.log('Erro ao efetuar o agendamento.', error);
    }
  };

  return (
    <div>
      <Header
        absolute
        color="secondary"
        brand="DriveSocial"
        logo={logo}
        logoalt={logoalt}
        rightLinks={
          <HeaderLinks
            cart
            user={{
              uid: state?.user?.uid || null,
              name:
                state?.user?.personal?.name ||
                state?.user?.fullname ||
                'Sem nome',
              surname: state?.user?.personal?.surname || '',
              email: state?.user?.email,
            }}
          />
        }
        isCart="true"
      />
      <div className={classes.pageHeader}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <Card className={classes.card}>
              <CardHeader color="primary" className={classes.cardHeader}>
                <Typography variant="subtitle1">
                  Agende o melhor dia e horário pra você
                </Typography>
              </CardHeader>
              <CardBody>
                <Grid item container direction="column" spacing={3}>
                  <Grid
                    item
                    xs
                    style={{ alignSelf: 'center' }} // TODO: Colocar no CSS
                  >
                    <InputLabel htmlFor="month-label">Mês</InputLabel>
                    <Select
                      className={classes.select}
                      native
                      value={scheduleDate?.month || ''}
                      onChange={handleScheduleMonth}
                      inputProps={{
                        name: 'month',
                        id: 'month-label',
                        style: { minWidth: '180px', textAlignLast: 'center' },
                      }}
                    >
                      <option aria-label="None" value="" />
                      {Object.prototype.hasOwnProperty.call(
                        scheduleDate,
                        'months'
                      ) &&
                        scheduleDate.months.map((obj, key) => {
                          return (
                            <option key={key} value={obj.month}>
                              {GLOBAL.MONTHS[obj.month]}
                            </option>
                          );
                        })}
                    </Select>
                  </Grid>
                  <Grid
                    item
                    xs
                    style={{ alignSelf: 'center' }} // TODO: Colocar no CSS
                  >
                    <InputLabel htmlFor="day-label">Dia</InputLabel>
                    <Select
                      className={classes.select}
                      native
                      value={scheduleDate?.day || ''}
                      onChange={handleScheduleDay}
                      inputProps={{
                        name: 'day',
                        id: 'day-label',
                        style: { minWidth: '180px', textAlignLast: 'center' },
                      }}
                    >
                      <option aria-label="None" value="" />
                      {Object.prototype.hasOwnProperty.call(
                        scheduleDate,
                        'days'
                      ) &&
                        scheduleDate.days.map((obj, key) => {
                          return (
                            <option key={key} value={obj.day}>
                              {obj.day}
                            </option>
                          );
                        })}
                    </Select>
                  </Grid>
                  <Grid
                    item
                    xs
                    style={{ alignSelf: 'center' }} // TODO: Colocar no CSS
                  >
                    <InputLabel htmlFor="hour-label">Hora</InputLabel>
                    <Select
                      className={classes.select}
                      native
                      value={scheduleDate?.hour || ''}
                      onChange={handleScheduleHour}
                      inputProps={{
                        name: 'hour',
                        id: 'hour-label',
                        style: { minWidth: '180px', textAlignLast: 'center' },
                      }}
                    >
                      <option aria-label="None" value="" />
                      {Object.prototype.hasOwnProperty.call(
                        scheduleDate,
                        'hours'
                      ) &&
                        scheduleDate.hours.map((obj, key) => {
                          //   console.log('>>>obj: ', obj);
                          return (
                            <option
                              key={key}
                              //   year={obj.year}
                              value={`${obj.hour}:${
                                obj.minute === 0 ? '00' : obj.minute
                              }`}
                              data-info={`${obj}`}
                            >
                              {`${obj.hour}:${
                                obj.minute === 0 ? '00' : obj.minute
                              }`}
                            </option>
                          );
                        })}
                    </Select>
                  </Grid>
                </Grid>
              </CardBody>
              <CardFooter className={classes.cardFooter}>
                <Button
                  // simple
                  color="primary"
                  // size="lg"
                  // disabled={!login.validEmail || !login.validPassword}
                  onClick={handleSubmit}
                >
                  Efetuar agendamento
                </Button>
              </CardFooter>
            </Card>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
