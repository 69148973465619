import { useState, useContext, useCallback, useEffect } from 'react';
import { makeStyles, Icon, InputAdornment, Fade, CircularProgress } from '@material-ui/core';
import { Email } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';

import Header from '../../components/header/Header';
import GridContainer from '../../components/grid/GridContainer';
import GridItem from '../../components/grid/GridItem';
import Button from '../../components/custom-buttons/Button.js';
import Card from '../../components/card/Card';
import CardBody from '../../components/card/CardBody';
import CardHeader from '../../components/card/CardHeader';
import CardFooter from '../../components/card/CardFooter';
import CustomInput from '../../components/custom-input/CustomInput';
import SnackbarContent from '../../components/snackbar/SnackbarContent';
import Footer from '../../components/footer/Footer';

import styles from '../../assets/jss/material-kit-react/views/loginPageStyle.js';
import logo from 'assets/img/ds_logo_write.png';
import logoalt from 'assets/img/ds_logo_wine.png';
import image from 'assets/img/traffic-full-web_big.jpg';

import { AuthContext } from '../../services/auth/auth-context';

const useStyles = makeStyles(styles);

export default function LoginPage(props) {
  const history = useHistory();
  const location = useLocation();
  const [cardAnimaton, setCardAnimation] = useState('cardHidden');
  const { state, signIn } = useContext(AuthContext);
  const [login, setLogin] = useState({
    email: '',
    password: '',
    validEmail: false,
    validPassword: false,
    errorLogin: false,
  });
  const classes = useStyles();
  const { ...rest } = props;
  const locationState = location?.state || null;
  const innerState = location?.state?.from?.state || null;
  const [isLoading, setIsLoading] = useState(true);
  // const [showLogin, setShowLogin] = useState(false);

  // const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const navigateTo = useCallback(() => {
    if (innerState?.fromCart) {
      history.push('/order', innerState);
    } else if (innerState?.fromReserve) {
      history.push('/schedule');
    } else {
      history.push('/', locationState);
    }
  }, [history, innerState, locationState]);

  // useEffect(() => {
  //   let interval = delay(2000).then((res) => {
  //     setIsLoading(false);
  //     // setShowLogin(true);
  //     // setCardAnimation('');
  //   });

  //   return () => clearTimeout(interval);
  // }, []);

  useEffect(() => {
    let interval = setTimeout(() => {
      setCardAnimation('');
      setIsLoading(false);
    }, 700);

    return () => clearTimeout(interval);
  }, []);

  useEffect(() => {
    if (state?.isAuthenticated && state?.user?.uid) {
      navigateTo();
    }
  }, [navigateTo, state.isAuthenticated, state?.user?.uid]);

  const changeValidEmail = (boolean) => {
    setLogin((login) => {
      return {
        ...login,
        validEmail: boolean,
      };
    });
  };
  const changeValidPassword = (boolean) => {
    setLogin((login) => {
      return {
        ...login,
        validPassword: boolean,
      };
    });
  };
  const changeErrorLogin = (boolean) => {
    setLogin((login) => {
      return {
        ...login,
        errorLogin: boolean,
      };
    });
  };

  const validateEmail = (email) => {
    // const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    !regEmail.test(email) ? changeValidEmail(false) : changeValidEmail(true);
  };

  const validatePassword = (password) => {
    password.length < 6 ? changeValidPassword(false) : changeValidPassword(true);
  };

  const handleEmail = (event) => {
    const email = event?.target?.value || '';
    validateEmail(email);
    setLogin((login) => {
      return {
        ...login,
        email,
      };
    });
  };

  const handlePassword = (event) => {
    const password = event?.target?.value || '';
    validatePassword(password);
    setLogin((login) => {
      return {
        ...login,
        password,
      };
    });
  };

  const handleLogin = async () => {
    changeErrorLogin(false);
    const { email, password } = login;
    const response = await signIn({ user: email, password });
    changeErrorLogin(!response);
  };

  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="DriveSocial"
        logo={logo}
        logoalt={logoalt}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: 'url(' + image + ')',
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            {isLoading && (
              <div className={classes.loading}>
                <Fade in={isLoading} unmountOnExit>
                  <CircularProgress color="secondary" />
                </Fade>
              </div>
            )}
            {!isLoading && (
              <GridItem xs={12} sm={12} md={4}>
                <Card className={classes[cardAnimaton]}>
                  <form className={classes.form}>
                    <CardHeader color="primary" className={classes.cardHeader}>
                      <h4>Login do App</h4>
                    </CardHeader>
                    <CardBody>
                      <CustomInput
                        labelText="Email..."
                        id="email"
                        error={!login.validEmail}
                        required
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: 'email',
                          value: login?.email || '',
                          required: true,
                          onChange: handleEmail,
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputIconsColor} />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <CustomInput
                        labelText="Senha"
                        id="pass"
                        error={!login.validPassword}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: 'password',
                          value: login?.password || '',
                          onChange: handlePassword,
                          endAdornment: (
                            <InputAdornment position="end">
                              <Icon className={classes.inputIconsColor}>lock_outline</Icon>
                            </InputAdornment>
                          ),
                          autoComplete: 'off',
                        }}
                      />
                    </CardBody>
                    <CardFooter className={classes.cardFooter}>
                      <Button
                        simple
                        color="primary"
                        size="lg"
                        disabled={!login.validEmail || !login.validPassword}
                        onClick={handleLogin}
                      >
                        ENTRAR
                      </Button>
                    </CardFooter>
                  </form>
                </Card>

                {login.errorLogin && (
                  <SnackbarContent
                    message={
                      <span>
                        <b>E-mail</b> ou <b>senha</b> incorretos.
                      </span>
                    }
                    color="danger"
                    icon="info_outline"
                  />
                )}
              </GridItem>
            )}
          </GridContainer>
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
