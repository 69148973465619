import { useContext, useEffect, useMemo, useCallback, useReducer, useState } from 'react';

import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import ProductsPage from './views/products-page/ProductsPage';
import CartPage from './views/cart-page/CartPage';
import LoginPage from './views/login-page/LoginPage';
import OrderPage from './views/order-page/OrderPage';
import ReservePage from './views/reserve-page/ReservePage';
import SchedulePage from './views/schedule-page/SchedulePage';

import {
  SIGN_IN,
  SIGN_OUT,
  SET_USER,
  RESTORE_TOKEN,
  FROM_MOBILE,
  initialState,
  reducer,
  AuthContext,
} from './services/auth/auth-context';
import AuthServices from './services/auth/auth-services';
import { session } from './services/storage';

function App() {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
  });
  const [hasReserve, setHasReserve] = useState(false);

  const authContext = useMemo(
    () => ({
      state,
      dispatch,
      signIn: async (loginData) => {
        try {
          const sessionkey = await AuthServices.getToken(loginData).then((res) => {
            const { sessionkey } = res.data;
            session.setItem('sessionkey', sessionkey);
            dispatch({ type: SIGN_IN, token: sessionkey });
            return sessionkey;
          });
          await AuthServices.getUser(sessionkey).then((res) => {
            const user = res.data;
            dispatch({ type: SET_USER, user });
          });
          return true;
        } catch (error) {
          return false;
        }
      },
      signOut: async () => {
        session.clear();
        dispatch({ type: SIGN_OUT });
      },
      signUp: async (data) => {
        // dispatch({ type: SIGN_UP });
      },
      setUser: (user) => {
        dispatch({ type: SET_USER, user });
      },
      updateUser: async (sessionkey) => {
        try {
          const response = await AuthServices.getUser(sessionkey);
          const user = response?.data || null;
          if (user) {
            session.setItem('sessionkey', sessionkey);
            dispatch({ type: RESTORE_TOKEN, token: sessionkey });
            dispatch({ type: SET_USER, user });
          } else {
            new Error('usuário não existe!');
          }
          return response;
        } catch (error) {
          session.clear();
          dispatch({ type: SIGN_OUT });
          console.warn('Erro ao atualizar usuário: ', error);
        }
      },
      setFromMobile: (boolean) => {
        dispatch({ type: FROM_MOBILE, boolean });
      },
    }),
    [state]
  );

  const getUser = useCallback(async (sessionkey) => {
    const response = await AuthServices.getUser(sessionkey);
    const user = response?.data || {};
    dispatch({ type: SET_USER, user });
  }, []);

  useEffect(() => {
    const sessionkey = session.getItem('sessionkey');
    if (sessionkey) {
      dispatch({ type: SIGN_IN, token: sessionkey });
      getUser(sessionkey);
    }
  }, [getUser, state.isAuthenticated]);

  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(state?.user, 'reserve')) {
      setHasReserve(true);
    } else {
      setHasReserve(false);
    }
  }, [state?.user]);

  const PrivateRoute = ({ children, ...rest }) => {
    const { state } = useContext(AuthContext);
    return (
      <Route
        {...rest}
        render={({ location }) => {
          return state.isAuthenticated && state.user.uid ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: `/login/`,
                state: { from: location },
              }}
            />
          );
        }}
      />
    );
  };

  // const NewPrivateRoute = ({ component: Component, ...rest }) => {
  //   const { state } = useContext(AuthContext);
  //   const location = useLocation();
  //   return (
  //     <Route
  //       {...rest}
  //       render={(routeProps) => {
  //         console.log('App.NewPrivateRoute.Route.routeProps: ', routeProps);
  //         return state.isAuthenticated && state.user.uid ? (
  //           <Component {...routeProps} />
  //         ) : (
  //           // <Redirect to={{ pathname: `${routeProps?.location?.pathname}/` }} />
  //           <Redirect
  //             to={{
  //               pathname: `/login/`,
  //               state: { from: routeProps?.location },
  //             }}
  //           />
  //         );
  //       }}
  //     />
  //   );
  // };

  return (
    <AuthContext.Provider value={authContext}>
      {/* <BrowserRouter> */}
      {/* <Router history={customHistory}> */}
      <Router>
        <Switch>
          <Route
            exact
            path="/"
            render={(routeProps) => {
              let to = '/reserve';
              const { state } = routeProps?.location;
              if (state) {
                to = state?.from?.pathname || '/reserve';
              }
              if (hasReserve) {
                return <Redirect to={to} />;
              } else {
                return <ProductsPage />;
              }
            }}
          />
          <Route path="/products">
            {hasReserve ? <Redirect to="/reserve" /> : <ProductsPage />}
          </Route>
          <Route path="/cart/:sessionkey?/:id?">
            {hasReserve ? <Redirect to="/reserve" /> : <CartPage />}
          </Route>
          <Route path="/login" component={LoginPage} />
          <PrivateRoute path="/order">
            {hasReserve ? <Redirect to="/reserve" /> : <OrderPage />}
          </PrivateRoute>
          <PrivateRoute path="/reserve/:backurl?">
            <ReservePage />
          </PrivateRoute>
          {/* <NewPrivateRoute path="/reserve/:backurl?" component={ReservePage} /> */}
          <PrivateRoute path="/schedule">
            <SchedulePage />
          </PrivateRoute>
        </Switch>
      </Router>
      {/* </BrowserRouter> */}
    </AuthContext.Provider>
  );
}

export default App;
