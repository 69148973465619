const GLOBAL = {
  AUTH: {
    CONTEXT: {
      RESTORE_TOKEN: 'RESTORE_TOKEN',
      SIGN_IN: 'SIGN_IN',
      SIGN_UP: 'SIGN_UP',
      SIGN_OUT: 'SIGN_OUT',
      SET_USER: 'SET_USER',
      FROM_MOBILE: 'FROM_MOBILE',
    },
  },
  PAYANDGO: {
    ADDRESS: {
      zip_code: '60160-196',
      addr_city: 'Fortaleza',
      addr_state: 'CE',
      addr_street_name: 'Av. Dom Luís',
      addr_street_number: '176',
      addr_street_neighborhood: 'Aldeota',
      addr_street_details: 'Ed. Mercury',
    },
  },
  STORAGE: {
    PREFIX: '@ds-price',
  },
  MONTHS: [
    '',
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
  BOX_VALUE: 5,
  SECURITY_PERCENTAGEM: 0.02,
  TEXT: {
    MAIN: `Acione o SOS sem precisar tocar na tela do seu smartphone.
    O PaniKey é o botão de pânico do DriveSocial, e permite que
    você peça ajuda de maneira discreta, sem precisar encostar
    no seu smartphone.`,
    _PLAN: ['This is ', <strong>not</strong>, ' working.'],
    PLAN: [
      `Escolha seu plano à partir da sua necessidade de botões.
    Todos os nossos planos incluem 90 dias de Conta Premium para cada botão adquirido.
    Para mais informações do planos acesse `,
      <a href="https://drivesocial.io/conta-premium/">drivesocial.io/conta-premium</a>,
    ],
  },
};

const API = {
  BASE_URL: 'https://checkout-api.drivesocial.io',
  BUSCA_CEP: 'https://buscacepinter.correios.com.br/app/endereco/index.php',
};

export { GLOBAL, API };
