import React from 'react';

import { GLOBAL } from '../../constants';

const RESTORE_TOKEN = GLOBAL.AUTH.CONTEXT.RESTORE_TOKEN; //"RESTORE_TOKEN";
const SIGN_IN = GLOBAL.AUTH.CONTEXT.SIGN_IN; //"SIGN_IN";
const SIGN_UP = GLOBAL.AUTH.CONTEXT.SIGN_UP; //"SIGN_UP";
const SIGN_OUT = GLOBAL.AUTH.CONTEXT.SIGN_OUT; //"SIGN_OUT";
const SET_USER = GLOBAL.AUTH.CONTEXT.SET_USER; //"SET_USER";
const FROM_MOBILE = GLOBAL.AUTH.CONTEXT.FROM_MOBILE; //"FROM_MOBILE";

export { RESTORE_TOKEN, SIGN_IN, SIGN_UP, SIGN_OUT, SET_USER, FROM_MOBILE };

export const initialState = {
  isAuthenticated: false,
  isFromMobile: false,
  userToken: null,
  user: {
    uid: null,
    email: null,
    fullname: null,
    sessionkey: null,
    personal: {
      id: null,
      name: null,
      surname: null,
      email: null,
      phone: null,
      id_type: null,
      id_data: null,
      addr_street_name: null,
      addr_street_number: null,
      addr_street_details: null,
      zip_code: null,
    },
  },
};

export const reducer = (prevState, action) => {
  switch (action.type) {
    case RESTORE_TOKEN:
      return {
        ...prevState,
        userToken: action.token,
      };
    case SIGN_IN:
      return {
        ...prevState,
        isAuthenticated: true,
        userToken: action.token,
      };
    case SIGN_UP:
      return {
        ...prevState,
        isAuthenticated: false,
        userToken: null,
      };
    case SIGN_OUT:
      return {
        ...prevState,
        isAuthenticated: false,
        userToken: null,
        user: initialState.user,
      };
    case SET_USER:
      return {
        ...prevState,
        isAuthenticated: true,
        user: action.user,
      };
    case FROM_MOBILE:
      return {
        ...prevState,
        isFromMobile: action.boolean,
      };

    default:
      return initialState;
    // throw new Error();
  }
};

const authContext = {
  state: () => {},
  dispatch: () => {},
  signIn: () => {},
  signOut: () => {},
  signUp: () => {},
  setUser: () => {},
  updateUser: () => {},
  setFromMobile: () => {},
};

export const AuthContext = React.createContext(authContext);
