import {
  container,
  title,
  // primaryColor,
} from '../../material-kit-react.js';

const schedulePageStyle = {
  container: {
    ...container,
    zIndex: '2',
    position: 'relative',
    paddingTop: '20vh',
    color: '#FFFFFF',
    paddingBottom: '200px',
  },
  title: {
    ...title,
    marginTop: '8px',
    minHeight: '32px',
    textDecoration: 'none',
  },
  subtitle: {
    fontSize: '1.313rem',
    maxWidth: '500px',
    margin: '10px auto 0',
  },
  pageHeader: {
    minHeight: '100vh',
    height: 'auto',
    display: 'inherit',
    position: 'relative',
    margin: '0',
    padding: '0',
    border: '0',
    alignItems: 'center',
    '&:before': {
      // background: "rgba(0, 0, 0, 0.5)",
      background: '#f2f2f2',
    },
    '&:before,&:after': {
      position: 'absolute',
      zIndex: '1',
      width: '100%',
      height: '100%',
      display: 'block',
      left: '0',
      top: '0',
      content: '""',
    },
    '& footer li a,& footer li a:hover,& footer li a:active': {
      // color: "#FFFFFF",
    },
    '& footer': {
      position: 'absolute',
      bottom: '0',
      width: '100%',
    },
  },
  card: {
    maxWidth: '540px',
    // minWidth: '640px',
    // width: '200%',
  },
  select: {
    minWidth: '80px',
  },
  cardFooter: {
    justifyContent: 'space-around',
  },
};

export default schedulePageStyle;
