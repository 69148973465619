import { useContext, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';

import Header from '../../components/header/Header';
import HeaderLinks from '../../components/header/HeaderLinks';
import GridContainer from '../../components/grid/GridContainer';
import GridItem from '../../components/grid/GridItem';
import Parallax from '../../components/parallax/Parallax';

import styles from '../../assets/jss/material-kit-react/views/landingPage.js';
import logo from 'assets/img/ds_logo_write.png';
import logoalt from 'assets/img/ds_logo_wine.png';

import ProductSection from './sections/ProductSection.js';
import Footer from '../../components/footer/Footer';

import { AuthContext } from '../../services/auth/auth-context';

import { GLOBAL } from '../../constants';

const dashboardRoutes = [];
const useStyles = makeStyles(styles);
const backgroundImage = require('assets/img/traffic-full-web.jpg');

export default function ProductsPage(props) {
  const classes = useStyles();
  const { state } = useContext(AuthContext);
  const productsRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    let interval = setTimeout(() => {
      location?.state?.fromCart && productsRef.current.scrollIntoView({ behavior: 'smooth' });
    }, 400);

    return () => clearTimeout(interval);
  }, [location?.state]);

  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="DriveSocial"
        logo={logo}
        logoalt={logoalt}
        rightLinks={
          <HeaderLinks
            user={{
              uid: state?.user?.uid || null,
              name: state?.user?.personal?.name || state?.user?.fullname || 'Sem nome',
              surname: state?.user?.personal?.surname || '',
              email: state?.user?.email,
            }}
          />
        }
        fixed
        changeColorOnScroll={{
          height: 200,
          color: 'white',
        }}
      />
      {/* <Parallax filter image={require("assets/img/landing-bg.jpg")}> */}
      <Parallax filter image={backgroundImage.default}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>DriveSocial PaniKey</h1>
              <h4>{GLOBAL.TEXT.MAIN}</h4>
              <br />
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ProductSection ref={productsRef} />
        </div>
      </div>
      <Footer />
    </div>
  );
}
