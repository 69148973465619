import React, { useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { List, ListItem, Tooltip, makeStyles, Badge } from '@material-ui/core';
// import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

import Button from '../custom-buttons/Button';
import { session } from '../../services/storage';

import styles from '../../assets/jss/material-kit-react/components/headerLinksStyle';

import { AuthContext } from '../../services/auth/auth-context';

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  const history = useHistory();
  const { signOut } = useContext(AuthContext);
  const { cart, user } = props;
  const [plan, setPlan] = React.useState({});

  React.useEffect(() => {
    let cartFromStorage = session.getItem('cart') || {};

    if (Object.prototype.hasOwnProperty.call(cartFromStorage, 'plan')) {
      cartFromStorage && setPlan(cartFromStorage?.plan);
    }
  }, []);

  const handleLogout = async () => {
    await signOut().then(() => {
      history.push('/');
    });
  };

  return (
    <List className={classes.list}>
      {!cart && (
        <ListItem className={classes.listItem}>
          <Tooltip
            id="cart"
            title="Carrinho"
            placement={window.innerWidth > 959 ? 'top' : 'left'}
            classes={{ tooltip: classes.tooltip }}
          >
            <Link to={'/cart'} className={classes.link}>
              <Button
                href=""
                target="_blank"
                color="transparent"
                className={classes.navLink}
              >
                <Badge
                  badgeContent={plan?.qty || ''}
                  invisible={!Object.prototype.hasOwnProperty.call(plan, 'qty')}
                  color="secondary"
                >
                  <i className={classes.cartIcons + ' fas fa-shopping-cart'} />
                </Badge>
              </Button>
            </Link>
          </Tooltip>
        </ListItem>
      )}
      <ListItem className={classes.listItem}>
        {user.uid ? (
          <>
            <Tooltip
              id="login"
              title={`${user.name} ${user.surname} (${user.email})`}
              placement={window.innerWidth > 959 ? 'top' : 'left'}
              classes={{ tooltip: classes.tooltip }}
            >
              {/* <Link to={"/login"} className={classes.link}> */}
              <Button
                href=""
                color="transparent"
                target="_blank"
                className={classes.navLink}
              >
                {user.name}
              </Button>
              {/* </Link> */}
            </Tooltip>
            <Tooltip
              id="login"
              title={'Logout'}
              placement={window.innerWidth > 959 ? 'top' : 'left'}
              classes={{ tooltip: classes.tooltip }}
            >
              <Button
                href=""
                color="transparent"
                target="_blank"
                className={classes.logout}
                onClick={handleLogout}
              >
                <i className={classes.cartIcons + ' fas fa-sign-out-alt'} />
              </Button>
            </Tooltip>
          </>
        ) : (
          <Tooltip
            id="login"
            title="Faça seu login"
            placement={window.innerWidth > 959 ? 'top' : 'left'}
            classes={{ tooltip: classes.tooltip }}
          >
            <Link to={'/login'} className={classes.link}>
              <Button
                href=""
                color="transparent"
                target="_blank"
                className={classes.navLink}
              >
                Login
              </Button>
            </Link>
          </Tooltip>
        )}
      </ListItem>
      {/* {console.log("HeaderLink.cart: ", cart, " user: ", user)} */}
    </List>
  );
}
