import { useContext, useEffect, useState, useCallback, useMemo } from 'react';

import axios from 'axios';

import {
  makeStyles,
  Paper,
  Box,
  Typography,
  TextField,
  InputAdornment,
  Divider,
  Fade,
  CircularProgress,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import InputMask from 'react-input-mask';
import { Link, useLocation, useParams } from 'react-router-dom';

import Header from '../../components/header/Header';
import HeaderLinks from '../../components/header/HeaderLinks';
import GridContainer from '../../components/grid/GridContainer';
import GridItem from '../../components/grid/GridItem';
import Footer from '../../components/footer/Footer';
import Button from '../../components/custom-buttons/Button';

import { session } from '../../services/storage';
import ProductsServices from '../../services/products/products-services';
import ZipServices from '../../services/cart/zip-services';
import { AuthContext } from '../../services/auth/auth-context';

import styles from '../../assets/jss/material-kit-react/views/cartPageStyle.js';
import logo from 'assets/img/ds_logo_write.png';
import logoalt from 'assets/img/ds_logo_wine.png';

import { GLOBAL, API } from '../../constants';

const useStyles = makeStyles(styles);

export default function CartPage(props) {
  const location = useLocation();
  const { sessionkey, id } = useParams();
  const planFromLocation = useMemo(() => location?.state?.plan || {}, [location?.state?.plan]);
  const { state, updateUser, setFromMobile } = useContext(AuthContext);
  const classes = useStyles();

  const [plans, setPlans] = useState([]);
  const [cart, setCart] = useState({});
  const [zipCode, setZipCode] = useState('');
  const [checkPayAndGo, setCheckPayAndGo] = useState(true);
  const [status, setStatus] = useState({
    hasZip: false,
    calculating: 'idle', // idle, progress, success
    hasZipError: false,
    visibleCheckPayAngGo: false,
    hasSessionError: false,
  });

  const formatStringToNumber = (number) => {
    return parseFloat(String(number).replace(',', '.'));
  };

  const formatNumberToString = useCallback((number) => {
    return String(formatStringToNumber(number)).replace('.', ',');
  }, []);

  const formatNumberToCurrency = (number) => {
    return formatStringToNumber(number).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  const loadPlans = useCallback(async (sourceCancel) => {
    try {
      const response = await ProductsServices.getPlans(sourceCancel);
      setPlans(response?.data || []);
    } catch (error) {
      console.log('Erro ao carregar planos: ', error);
      throw error;
    }
  }, []);

  const loadCart = useCallback(() => {
    const plan = plans.filter((plan) => {
      return plan?.id === parseInt(id);
    })[0];

    setCart((cart) => {
      return {
        ...cart,
        plan,
        payAndGo: false,
        summary: {
          ...cart?.summary,
          plan: String(plan?.price || 0),
          total: String(plan?.price || 0),
        },
      };
    });
    setCheckPayAndGo(false);
  }, [id, plans]);

  const checkSession = useCallback(
    async (routeToken) => {
      updateUser(routeToken).then((res) => {
        if (res && res?.data?.uid) {
          changeHasSessionError(false);
        } else {
          changeHasSessionError(true);
        }
      });
    },
    [updateUser]
  );

  const fromMobile = useCallback(
    (boolean) => {
      setFromMobile(boolean);
    },
    [setFromMobile]
  );

  const changeFreight = useCallback(
    (freight) => {
      setCart((cart) => {
        return {
          ...cart,
          summary: {
            ...cart.summary,
            freight,
            total: formatNumberToString(
              formatStringToNumber(cart?.summary?.plan || 0) + formatStringToNumber(freight)
            ),
          },
        };
      });
    },
    [formatNumberToString]
  );

  const calculateFreight = useCallback(
    async ({ zipUnmask, sourceCancel }) => {
      const plan = cart?.summary?.plan || 0;
      const box = GLOBAL.BOX_VALUE || 0;
      const perSec = GLOBAL.SECURITY_PERCENTAGEM || 0;
      const plus = parseFloat(plan) * perSec + parseFloat(box);
      const response = await ZipServices.getCostOfFreight({
        zipUnmask,
        sourceCancel,
      });
      setCart((cart) => {
        return {
          ...cart,
          costFromCorreios: response?.data[0] || {},
        };
      });
      const freight = parseFloat(response?.data[0]?.Valor);
      return formatNumberToString(freight + plus);
    },
    [cart?.summary?.plan, formatNumberToString]
  );

  const checkIfLocal = useCallback((zipUnmask) => {
    if (parseInt(zipUnmask) > 60000000 && parseInt(zipUnmask) < 61600000) {
      changeVisibleCheckPayAndGo(false); // TODO: Habilitar quando disponível
      changePayAndGo(false);
    } else {
      changeVisibleCheckPayAndGo(false);
      changePayAndGo(false);
      setCheckPayAndGo(false);
    }
  }, []);

  const getFreight = useCallback(
    async ({ zipUnmask, sourceCancel }) => {
      if (!cart?.payAndGo) {
        changeStatusCalculating('progress');
        const shippingValue = await calculateFreight({
          zipUnmask,
          sourceCancel,
        });
        changeFreight(shippingValue);
        changeStatusCalculating('success');
      } else {
        changeFreight(0);
        changeStatusCalculating('success');
      }
    },
    [calculateFreight, cart?.payAndGo, changeFreight]
  );

  const getAddress = useCallback(
    async ({ zipUnmask, sourceCancel }) => {
      try {
        changeStatusCalculating('progress');
        checkIfLocal(zipUnmask);
        const resAddress = await ZipServices.getZipCode({
          zipUnmask,
          sourceCancel,
        });
        changeAddressByPostOffice(resAddress?.data);
        changeStatusHasZip({ hasZip: true, hasZipError: false });
        changeStatusCalculating('success');
      } catch (error) {
        changeStatusHasZip({ hasZip: true, hasZipError: true });
        changeStatusCalculating('idle');
        throw error;
      }
    },
    [checkIfLocal]
  );

  // ===================== Start Effects

  useEffect(() => {
    const cartOfStorage = session.getItem('cart') || {};

    Object.prototype.hasOwnProperty.call(cartOfStorage, 'plan') && setCart(cartOfStorage);

    Object.prototype.hasOwnProperty.call(cartOfStorage, 'addressByPostOffice') &&
      setZipCode(cartOfStorage.addressByPostOffice?.cep);

    Object.prototype.hasOwnProperty.call(cartOfStorage, 'payAndGo') &&
      setCheckPayAndGo(cartOfStorage.payAndGo);
  }, []);

  useEffect(() => {
    const cancelPlans = axios.CancelToken.source();
    const plansOfStorage = session.getItem('plans');

    if (plansOfStorage && plansOfStorage.length > 0) {
      setPlans(plansOfStorage);
    } else {
      loadPlans(cancelPlans);
    }

    return () => cancelPlans.cancel();
  }, [loadPlans]);

  useEffect(() => {
    if (state.isAuthenticated) {
      const personal = state?.user?.personal || null;
      if (personal) {
        const zip_code = personal?.zip_code || null;
        zip_code && setZipCode(String(zip_code));
      }
    }
  }, [state.isAuthenticated, state?.user?.personal]);

  useEffect(() => {
    if (sessionkey && !state.isFromMobile) {
      session.setItem('isFromMobile', true);
      fromMobile(true);
      checkSession(sessionkey);
    } else if (!sessionkey && !state.isFromMobile) {
      session.setItem('isFromMobile', false);
    }
  }, [checkSession, fromMobile, sessionkey, state.isFromMobile]);

  useEffect(() => {
    if (state.isFromMobile && id) {
      loadCart();
    }
  }, [sessionkey, id, state.isFromMobile, plans, loadCart]);

  useEffect(() => {
    session.setItem('plans', plans);
  }, [plans]);

  useEffect(() => {
    session.setItem('cart', cart);
  }, [cart]);

  useEffect(() => {
    const price = planFromLocation?.price || null;
    if (price) {
      const freight = cart?.summary?.freight || 0;
      setCart((cart) => {
        return {
          ...cart,
          plan: planFromLocation,
          summary: {
            ...cart?.summary,
            plan: String(price),
            total: formatNumberToString(
              formatStringToNumber(price) + formatStringToNumber(freight)
            ),
          },
        };
      });
    }
  }, [cart?.summary?.freight, formatNumberToString, planFromLocation]);

  // 1 - Se tiver CEP válido pega o endereço e aplica no cart
  useEffect(() => {
    const cancelAddress = axios.CancelToken.source();
    const zipUnmask = String(zipCode).replace(/[^\d]/g, '');

    if (zipUnmask.length === 8) {
      getAddress({ zipUnmask, sourceCancel: cancelAddress });
    } else {
      changeStatusHasZip({ hasZip: false, hasZipError: false });
      changeVisibleCheckPayAndGo(false);
    }

    return () => cancelAddress.cancel();
  }, [zipCode, getAddress]);

  // 2 - Se o endereço do cart mudar pega o valor do frete
  useEffect(() => {
    const cancelFreight = axios.CancelToken.source();
    const zipUnmask = String(cart?.addressByPostOffice?.cep).replace(/[^\d]/g, '');
    zipUnmask.length === 8 && getFreight({ zipUnmask, sourceCancel: cancelFreight });

    return () => cancelFreight.cancel();
  }, [cart?.addressByPostOffice?.cep, getFreight]);

  // ===================== End Effects

  const changeAddressByPostOffice = (address) => {
    const { cep, localidade, uf, logradouro, bairro } = address || {};
    setCart((cart) => {
      return {
        ...cart,
        addressByPostOffice: address,
        address: {
          ...cart.address,
          zip_code: cep || '',
          addr_city: localidade || '',
          addr_state: uf || '',
          addr_street_name: logradouro || '',
          addr_street_number: '',
          addr_street_neighborhood: bairro || '',
          addr_street_details: '',
        },
      };
    });
  };

  const changeStatusCalculating = (calculating) => {
    setStatus((status) => {
      return {
        ...status,
        calculating,
      };
    });
  };

  const changeStatusHasZip = ({ hasZip, hasZipError }) => {
    setStatus((status) => {
      return {
        ...status,
        hasZip,
        hasZipError,
      };
    });
  };

  const changeHasSessionError = (hasSessionError) => {
    setStatus((status) => {
      return {
        ...status,
        hasSessionError,
      };
    });
  };

  const changePayAndGo = (payAndGo) => {
    setCheckPayAndGo(payAndGo);
    setCart((cart) => {
      return {
        ...cart,
        payAndGo,
      };
    });
  };

  const changeVisibleCheckPayAndGo = (visibleCheckPayAngGo) => {
    setStatus((status) => {
      return {
        ...status,
        visibleCheckPayAngGo,
      };
    });
  };

  const handleChangeZipCode = (event) => {
    const newZip = event.target.value;
    setZipCode(newZip);
  };

  const handleClickCalculateFreight = () => {
    const zipUnmask = String(zipCode).replace(/[^\d]/g, '');
    zipUnmask.length === 8 && getAddress({ zipUnmask, sourceCancel: () => {} });
  };

  const handleCheckPayAndGo = (event) => {
    const checked = event.target.checked;
    changePayAndGo(checked);
  };

  return (
    <div>
      {!state.isFromMobile && (
        <Header
          absolute
          color="secondary"
          brand="DriveSocial"
          logo={logo}
          logoalt={logoalt}
          rightLinks={
            <HeaderLinks
              cart
              user={{
                uid: state?.user?.uid || null,
                name: state?.user?.personal?.name || state?.user?.fullname || 'Sem nome',
                surname: state?.user?.personal?.surname || '',
                email: state?.user?.email,
              }}
            />
          }
          isCart="true"
        />
      )}
      <div className={classes.pageHeader}>
        <div className={!state.isFromMobile ? classes.container : classes.containerMobile}>
          {/* Grid externo */}
          {status.hasSessionError && (
            <Box color="error.main">
              <Typography variant="h5" gutterBottom>
                Sua sessão não é válida.
                <br />
                Por favor, repita o procedimento.
              </Typography>
            </Box>
          )}
          {!status.hasSessionError && !cart?.plan?.qty && (
            <Box color="error.main">
              <Typography variant="h5" gutterBottom>
                Seu carrinho está vazio.
              </Typography>
              <Typography variant="subtitle2" gutterBottom>
                Você precisa selecionar um plano{' '}
                <Link to={{ pathname: '/products', state: { fromCart: true } }}>aqui.</Link>
              </Typography>
            </Box>
          )}
          {!status.hasSessionError && cart?.plan?.qty && (
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8}>
                {/* Gride Produto e CEP */}
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={12}>
                    <Paper className={classes.paper} elevation={1}>
                      {/* Gride Produtos */}
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={7} md={7}>
                          <TextField
                            id="plan-name"
                            label="Plano"
                            value={cart.plan.name}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              readOnly: true,
                            }}
                            margin="normal"
                            className={classes.productName}
                          />
                        </GridItem>
                        <GridItem xs={6} sm={2} md={2}>
                          <TextField
                            id="plan-qty"
                            label="Quantidade"
                            type="number"
                            value={cart.plan.qty}
                            InputLabelProps={{
                              shrink: true,
                              style: { textAlign: 'center' },
                            }}
                            inputProps={{
                              readOnly: true,
                              style: { textAlign: 'center' },
                            }}
                            margin="normal"
                            className={classes.productNumber}
                          />
                        </GridItem>
                        <GridItem xs={6} sm={3} md={3}>
                          <TextField
                            id="plan-value"
                            label="Valor"
                            value={cart.plan.price}
                            InputProps={{
                              readOnly: true,
                              startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                            }}
                            margin="normal"
                            className={classes.productValue}
                          />
                        </GridItem>
                      </GridContainer>
                    </Paper>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Paper className={classes.paper} elevation={1}>
                      {/* Gride CEP */}
                      <GridContainer justify="flex-start">
                        <GridItem xs={6} sm={3} md={3}>
                          <InputMask
                            mask="99999-999"
                            maskChar=" "
                            maskPlaceholder={null}
                            value={zipCode || ''}
                            onChange={handleChangeZipCode}
                          >
                            {() => (
                              <TextField
                                required
                                error={status.hasZipError}
                                id="zipCode-number"
                                label="Calcule o frete"
                                placeholder="Ex.: 12345-678"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                margin="normal"
                                className={classes.productName}
                              />
                            )}
                          </InputMask>
                        </GridItem>
                        <GridItem
                          xs={3}
                          sm={3}
                          md={3}
                          id="grideItemZIP"
                          style={{
                            marginTop: '16px',
                            marginBottom: '8px',
                            textAlign: 'center',
                          }} // TODO: colocar no CSS
                        >
                          <Button
                            color="info"
                            onClick={handleClickCalculateFreight}
                            disabled={!status.hasZip || status.hasZipError}
                          >
                            Calcular
                          </Button>
                        </GridItem>
                        <GridItem
                          xs={6}
                          sm={3}
                          md={2}
                          style={{
                            marginTop: '30px',
                            marginBottom: '8px',
                            textAlign: 'center',
                            color: '#880e4f',
                          }} // TODO: colocar no CSS
                        >
                          <a
                            style={{ color: '#880e4f' }}
                            href={API.BUSCA_CEP}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Busca CEP
                          </a>
                        </GridItem>
                        <GridItem
                          xs={6}
                          sm={4}
                          md={4}
                          style={{
                            marginTop: '20px',
                            marginBottom: '8px',
                            textAlign: 'center',
                          }} // TODO: colocar no CSS
                        >
                          {status.visibleCheckPayAngGo && (
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={checkPayAndGo}
                                  onChange={handleCheckPayAndGo}
                                  value="checkPayAndGo"
                                  disabled={!status.hasZip || status.hasZipError}
                                  classes={{
                                    switchBase: classes.switchBase,
                                    checked: classes.switchChecked,
                                    thumb: classes.switchIcon,
                                    track: classes.switchBar,
                                  }}
                                />
                              }
                              classes={{
                                label: classes.label,
                              }}
                              label={checkPayAndGo ? 'Colete no local' : 'Entrega padrão'}
                            />
                          )}
                        </GridItem>
                      </GridContainer>
                    </Paper>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Paper
                  className={
                    status.calculating === 'progress' ? classes.paperLoading : classes.paper
                  }
                  elevation={1}
                >
                  <Box component="span" color="textFloat.primary">
                    <h4 className={classes.title}>Resumo do pedido</h4>
                  </Box>
                  <div className={classes.resumeLoading}>
                    <Fade in={status.calculating === 'progress'} unmountOnExit>
                      <CircularProgress color="secondary" />
                    </Fade>
                  </div>
                  <GridContainer justify="space-around" className={classes.resumeItem}>
                    <GridItem xs={7} sm={7} md={7}>
                      {cart?.plan?.qty} plano{cart?.plan?.qty > 1 ? 's' : ''} Premium
                    </GridItem>
                    <GridItem xs={5} sm={5} md={5} style={{ textAlign: 'center' }}>
                      {formatNumberToCurrency(cart?.summary?.plan)}
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify="space-around" className={classes.resumeItem}>
                    <GridItem xs={7} sm={7} md={7}>
                      Frete
                    </GridItem>
                    <GridItem xs={5} sm={5} md={5} style={{ textAlign: 'center' }}>
                      {status.hasZipError
                        ? 'CEP inválido!'
                        : status.hasZip
                        ? formatNumberToCurrency(cart?.summary?.freight || '0,00')
                        : 'Informe o CEP!'}
                    </GridItem>
                  </GridContainer>
                  <Divider variant="inset" />
                  <GridContainer justify="space-around" className={classes.resumeItem}>
                    <GridItem xs={7} sm={7} md={7}>
                      <b>Total</b>
                    </GridItem>
                    <GridItem xs={5} sm={5} md={5} style={{ textAlign: 'center' }}>
                      {/* // TODO: colocar no CSS */}
                      <b>{formatNumberToCurrency(cart?.summary?.total)}</b>
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify="center" className={classes.resumeButton}>
                    <GridItem xs={12} sm={12} md={12}>
                      <Button
                        color="primary"
                        disabled={
                          !status.hasZip || status.hasZipError || status.calculating !== 'success'
                        }
                        component={Link}
                        to={{
                          pathname: '/order',
                          state: { fromCart: true, cart },
                        }}
                      >
                        <ShoppingBasketIcon className={classes.icons} />
                        Continuar
                      </Button>
                    </GridItem>
                  </GridContainer>
                </Paper>
              </GridItem>
            </GridContainer>
          )}
        </div>
        {!state.isFromMobile && <Footer />}
      </div>
    </div>
  );
}
