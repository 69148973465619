import { useEffect, forwardRef, useState, useCallback } from 'react';

import axios from 'axios';

import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

import LooksOneIcon from '@material-ui/icons/LooksOne';
import LooksTwoIcon from '@material-ui/icons/LooksTwo';
import Looks3Icon from '@material-ui/icons/Looks3';

import GridContainer from '../../../components/grid/GridContainer';
import GridItem from '../../../components/grid/GridItem';
import InfoArea from '../../../components/info-area/InfoArea';
import Button from '../../../components/custom-buttons/Button.js';

import { session } from '../../../services/storage';
import ProductsServices from '../../../services/products/products-services';

import styles from '../../../assets/jss/material-kit-react/views/landingPageSections/productStyle';

import { GLOBAL } from '../../../constants';

const useStyles = makeStyles(styles);

const ProductSection = forwardRef((_props, ref) => {
  const classes = useStyles();
  const [plans, setPlans] = useState([]);

  const loadPlans = useCallback(async (sourceCancel) => {
    const response = await ProductsServices.getPlans(sourceCancel);
    setPlans(response?.data || []);
  }, []);

  useEffect(() => {
    const cancelPlans = axios.CancelToken.source();

    loadPlans(cancelPlans);

    return () => cancelPlans.cancel();
  }, [loadPlans]);

  useEffect(() => {
    session.setItem('plans', plans);
  }, [plans]);

  return (
    <div ref={ref} className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Escolha seu plano</h2>
          <h5 className={classes.description}>
            {GLOBAL.TEXT.PLAN.map((text, key) => {
              return <span key={key}>{text}</span>;
            })}
          </h5>
          <br />
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          {plans &&
            plans.length > 0 &&
            plans.map((plan, key) => {
              return (
                <GridItem
                  key={key}
                  xs={12}
                  sm={12}
                  md={4}
                  className={plan?.qty === 2 ? classes.gridItemContrast : classes.gridItem}
                >
                  {/* // TODO: colocar no CSS */}
                  <InfoArea
                    title={plan?.name || ''}
                    subtitle={'R$ ' + plan?.price || ''}
                    description={plan?.description || ''}
                    icon={
                      plan?.qty === 1 ? LooksOneIcon : plan.qty === 2 ? LooksTwoIcon : Looks3Icon
                    }
                    iconColor={plan?.qty === 1 ? 'info' : plan.qty === 2 ? 'success' : 'danger'}
                    vertical
                  />
                  <Link to={{ pathname: '/cart', state: { plan } }} className={classes.link}>
                    <Button color="primary">Comprar</Button>
                  </Link>
                </GridItem>
              );
            })}
        </GridContainer>
      </div>
    </div>
  );
});

export default ProductSection;

// export default function ProductSection(props) {

// }
