import axios from 'axios';

import { API } from '../../constants';
export default class ZipServices {
  static async getZipCode({ zipUnmask, sourceCancel }) {
    try {
      return await axios.get(`${API.BASE_URL}/correios/cep/${zipUnmask}`, {
        cancelToken: sourceCancel.token,
      });
    } catch (error) {
      if (axios.isCancel(error)) {
        console.warn(
          `Requisição cancelada${error?.message ? `: ${error?.message}` : '.'}`
        );
      } else {
        console.warn('Erro na pesquisa do CEP: ', error);
      }
    }
  }

  static async getCostOfFreight({ zipUnmask, sourceCancel }) {
    try {
      return await axios.post(
        `${API.BASE_URL}/correios/simular-frete`,
        {
          sCepOrigem: '60840-970', // Correios Messejana
          sCepDestino: zipUnmask,
          nVlPeso: '0.1',
          nCdFormato: '3',
          // 1 – Formato caixa/pacote
          // 2 – Formato rolo/prisma
          // 3 – Envelope
          nVlComprimento: '20',
          nVlAltura: '1',
          nVlLargura: '15',
          nCdServico: ['04510'],
          // 04014 SEDEX à vista
          // 04510 PAC à vista
          // 04782 SEDEX 12 ( à vista)
          // 04790 SEDEX 10 (à vista)
          // 04804 SEDEX Hoje à vista
          nVlDiametro: '0',
        },
        {
          cancelToken: sourceCancel.token,
        }
      );
    } catch (error) {
      if (axios.isCancel(error)) {
        console.warn(
          `Requisição cancelada${error?.message ? `: ${error?.message}` : '.'}`
        );
      } else {
        console.warn('Erro no custo do frete: ', error);
      }
    }
  }

  static async getDispatchTime({ sku, method, qty, sourceCancel }) {
    try {
      return await axios.get(`${API.BASE_URL}/qty/${sku}/${method}/${qty}`, {
        cancelToken: sourceCancel.token,
      });
    } catch (error) {
      if (axios.isCancel(error)) {
        console.warn(
          `Requisição cancelada${error?.message ? `: ${error?.message}` : '.'}`
        );
      } else {
        console.warn('Erro na pesquisa do SKU: ', error);
      }
    }
  }
}

// Envelope para NF 15x13cm
// https://www.nzbembalagens.com.br/envelope-awb-danfe-15x13
// Envelope com plático bolha 15x19cm
// https://www.nzbembalagens.com.br/envelope-com-plastico-bolha-para-correios-15x19
