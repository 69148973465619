import {
  container,
  title,
  // primaryColor,
} from '../../material-kit-react.js';

const reservePageStyle = {
  container: {
    ...container,
    zIndex: '2',
    position: 'relative',
    paddingTop: '20vh',
    color: '#FFFFFF',
    paddingBottom: '200px',
  },
  containerMobile: {
    ...container,
    zIndex: '2',
    position: 'relative',
    paddingTop: '5vh',
    color: '#FFFFFF',
    paddingBottom: '10vh',
  },
  title: {
    ...title,
    marginTop: '8px',
    minHeight: '32px',
    textDecoration: 'none',
  },
  subtitle: {
    fontSize: '1.313rem',
    maxWidth: '500px',
    margin: '10px auto 0',
  },
  label: {
    fontWeight: '400',
    margin: '0 10px 0 0',
  },
  pageHeader: {
    minHeight: '100vh',
    height: 'auto',
    display: 'inherit',
    position: 'relative',
    margin: '0',
    padding: '0',
    border: '0',
    alignItems: 'center',
    '&:before': {
      background: '#f2f2f2',
    },
    '&:before,&:after': {
      position: 'absolute',
      zIndex: '1',
      width: '100%',
      height: '100%',
      display: 'block',
      left: '0',
      top: '0',
      content: '""',
    },
    '& footer li a,& footer li a:hover,& footer li a:active': {},
    '& footer': {
      position: 'absolute',
      bottom: '0',
      width: '100%',
    },
  },
  loading: {
    // display: 'flex',
    position: 'absolute',
    // position: 'relative',
    top: '70%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  card: {
    maxWidth: '940px',
    background: '#f2f2f2',
  },
  paper: {
    padding: '10px',
  },
  cardFooter: {
    justifyContent: 'space-around',
  },
};

export default reservePageStyle;
